<template>
  <tbody class="tableLines">
    <tr class="rowResults">
      <td class="text-center rowResult">
        {{ item.data_inicio_brt | moment("DD/MM/YYYY HH:mm:ss") }}
      </td>
      <td class="text-center rowResult proj">
        {{ item.nome_projeto }}
      </td>
      <td class="text-center rowResult action">
        <div class="selectIcon">
          <button
            :id="'btnIconId' + index"
            class="btnIcon"
            v-clipboard:copy="item.identificacao_execucao"
            @click="copyInfo"
          >
            <span class="material-symbols-outlined btnIconInside"> badge </span>
          </button>
        </div>
        <b-tooltip
          :target="'btnIconId' + index"
          triggers="hover"
          placement="bottom"
          variant="secondary"
          class="tooltipResults"
          boundary="window"
        >
          {{ item.identificacao_execucao }}
        </b-tooltip>
      </td>
      <td class="text-center rowResult">
        <div v-if="item.tipo_pessoa == 'pessoa_fisica'" class="selectIcon">
          <button
            :id="'btnIconPF' + index"
            class="btnIcon"
            v-clipboard:copy="pessoaFisica"
            @click="copyInfo"
          >
            <span class="material-symbols-outlined btnIconInside">face</span>
          </button>
          <b-tooltip
            :target="'btnIconPF' + index"
            triggers="hover"
            placement="bottom"
            variant="secondary"
            class="tooltipResults"
            boundary="window"
          >
            {{ pessoaFisica }}
          </b-tooltip>
        </div>
        <div
          v-else-if="item.tipo_pessoa == 'pessoa_juridica'"
          class="selectIcon"
        >
          <button
            :id="'btnIconPJ' + index"
            class="btnIcon"
            v-clipboard:copy="pessoaJuridica"
            @click="copyInfo"
          >
            <span class="material-symbols-outlined">business</span>
          </button>
          <b-tooltip
            :target="'btnIconPJ' + index"
            triggers="hover"
            placement="bottom"
            variant="secondary"
            class="tooltipResults"
            boundary="window"
          >
            {{ pessoaJuridica }}
          </b-tooltip>
        </div>
        <div v-if="item.produto == 'v3/modelo'" class="selectIcon">
          <button
            :id="'btnIconFB' + index"
            class="btnIcon"
            v-clipboard:copy="fastBacktest"
            @click="copyInfo"
          >
            <span class="material-symbols-outlined"> speed </span>
          </button>
          <b-tooltip
            :target="'btnIconFB' + index"
            triggers="hover"
            placement="bottom"
            variant="secondary"
            class="tooltipResults"
            boundary="window"
          >
            <span>
              <strong style="color: white">{{ tipoConsulta }} </strong> <br />
              {{ item.cod_ref }}
            </span>
          </b-tooltip>
        </div>
        <div v-else-if="item.produto == 'v3/box'" class="selectIcon">
          <button
            :id="'btnIconC' + index"
            class="btnIcon"
            v-clipboard:copy="custom"
            @click="copyInfo"
          >
            <span class="material-symbols-outlined"> library_books </span>
          </button>
          <b-tooltip
            :target="'btnIconC' + index"
            triggers="hover"
            placement="bottom"
            variant="secondary"
            class="tooltipResults"
            boundary="window"
          >
            <span>
              <strong style="color: white">{{ tipoConsulta }} </strong> <br />
              {{ item.cod_ref }}
            </span>
          </b-tooltip>
        </div>
        <div v-else class="selectIcon">
          <button
            :id="'btnIconC' + index"
            class="btnIcon"
            v-clipboard:copy="custom"
            @click="copyInfo"
          >
            <span class="material-symbols-outlined">speed</span>
          </button>
          <b-tooltip
            :target="'btnIconC' + index"
            triggers="hover"
            placement="bottom"
            variant="secondary"
            class="tooltipResults"
            boundary="window"
          >
            Fast Backtest
          </b-tooltip>
        </div>
      </td>
      <td class="text-center rowResult">
        <div class="progress-box" :id="'btnIconProgress' + index">
          <div
            class="progress-bar"
            :style="{
              width:
                ((item.qtde_linhas_processadas - item.qtde_linhas_erro) /
                  item.qtde_linhas_total) *
                  100 +
                '%',
            }"
            :class="{
              canceled: item.texto_status == 'Cancelado',
              green:
                item.texto_status != 'Cancelado' &&
                item.texto_status != 'Terminado' &&
                item.texto_status != 'Erro',
              blue: item.texto_status == 'Terminado',
              red: item.texto_status == 'Erro',
            }"
          ></div>
          <div
            class="progress-bar red"
            :style="{
              width:
                (item.qtde_linhas_erro / item.qtde_linhas_total) * 100 + '%',
            }"
            :class="{ canceled: item.texto_status == 'Cancelado' }"
          ></div>
          <span class="progress-text" v-if="item.pct_completo !== 1">
            {{ (item.pct_completo * 100).toFixed(2) }}%
          </span>
          <span class="progress-text" v-else>
            {{ item.pct_completo * 100 }}%
          </span>
        </div>
        <b-tooltip
          v-if="item.mensagem_informativa != null"
          :target="'btnIconProgress' + index"
          triggers="hover"
          placement="bottom"
          variant="secondary"
          class="tooltipResults"
          boundary="window"
        >
          {{ item.mensagem_informativa }}
        </b-tooltip>

        <b-tooltip
          v-else-if="
            item.pct_completo != 0 && item.mensagem_informativa == null
          "
          :target="'btnIconProgress' + index"
          triggers="hover"
          placement="bottom"
          variant="secondary"
          class="tooltipResults"
          boundary="window"
        >
          Nome de usuario: {{ item.nome_usuario }} <br />
          Tempo estimado: {{ item.tempo_estimado | formatTimeWithDays }} <br />
          Linhas Totais: {{ formatarNumero(item.qtde_linhas_total) }} <br />
          Linhas Processadas:
          {{
            formatarNumero(item.qtde_linhas_processadas) +
            " (" +
            (item.pct_completo * 100).toFixed(2) +
            "%)"
          }}
          <br />
          Linhas Erros:
          {{
            formatarNumero(item.qtde_linhas_erro) +
            " (" +
            ((item.qtde_linhas_erro / item.qtde_linhas_total) * 100).toFixed(
              2
            ) +
            "%)"
          }}
          <br />
        </b-tooltip>
        <b-tooltip
          v-else-if="item.texto_status == 'Criado'"
          :target="'btnIconProgress' + index"
          triggers="hover"
          placement="bottom"
          variant="secondary"
          class="tooltipResults"
          boundary="window"
        >
          Aguardando inicialização da execução do Lote
        </b-tooltip>
      </td>
      <td class="text-center rowResult">
        <div class="linkLote">
          <p class="statusHist">{{ item.texto_status }}</p>
        </div>
      </td>
      <td class="text-center rowResult actions">
        <div class="linkLoteRow">
          <button
            v-if="
              item.texto_status == 'Pausado' || item.texto_status == 'Criado'
            "
            :id="'btnIconInicia' + index"
            class="btnStatus"
            :class="{ btnDisabled: limiteExecucaoUtilizado >= 1 }"
            @click="startLote(item.identificacao_execucao)"
            v-show="!disableCancel"
          >
            <span class="material-symbols-outlined colorGreen iconAlign"
              >play_circle</span
            >
          </button>
          <b-tooltip
            v-if="
              item.texto_status == 'Pausado' || item.texto_status == 'Criado'
            "
            :target="'btnIconInicia' + index"
            triggers="hover"
            placement="bottom"
            variant="secondary"
            boundary="window"
          >
            Iniciar Lote
            <p v-if="limiteExecucaoUtilizado >= 1">
              A execução deste Lote excede o seu
              <strong style="color: #fff">limite máximo de consultas</strong>
              permitido. <br />
              Por favor <a href="/suporte/contato">clique aqui</a> para enviar
              uma mensagem ao suporte e requisitar um ajuste em seu
              <strong style="color: #fff">limite de consultas</strong>.
            </p>
          </b-tooltip>
          <button
            v-if="item.texto_status == 'Iniciado'"
            :id="'btnIconPause' + index"
            class="btnStatus"
            @click="showAlertPause(item.identificacao_execucao)"
            v-show="!disableCancel"
          >
            <span class="material-symbols-outlined colorYellow iconAlign"
              >pause_circle</span
            >
          </button>
          <b-tooltip
            v-if="item.texto_status == 'Iniciado'"
            :target="'btnIconPause' + index"
            triggers="hover"
            placement="bottom"
            variant="secondary"
            boundary="window"
          >
            Pausar Lote
          </b-tooltip>
          <button
            v-if="
              item.texto_status == 'Iniciado' ||
              item.texto_status == 'Pausado' ||
              item.texto_status == 'Criado'
            "
            :id="'btnIconCancel' + index"
            class="btnStatus btnCancel"
            @click="showAlert(item.identificacao_execucao)"
            v-show="!disableCancel"
          >
            <span class="material-symbols-outlined colorRed iconAlign"
              >highlight_off</span
            >
          </button>
          <b-tooltip
            v-if="
              item.texto_status == 'Iniciado' ||
              item.texto_status == 'Pausado' ||
              item.texto_status == 'Criado'
            "
            :target="'btnIconCancel' + index"
            triggers="hover"
            placement="bottom"
            variant="secondary"
            boundary="window"
          >
            Cancelar Lote
          </b-tooltip>
          <button
            v-if="item.link_temporario_sucesso != 'N/A'"
            :id="'btnIconDownload' + index"
            class="btnStatus"
          >
            <span
              class="material-symbols-outlined iconAlign"
              :class="{
                colorBlue: item.texto_status == 'Terminado',
                colorYellow: item.texto_status == 'Pausado',
              }"
              @click="goLink(item.link_temporario_sucesso)"
              >file_download</span
            >
          </button>
          <b-tooltip
            v-if="
              item.texto_status == 'Pausado' &&
              item.link_temporario_sucesso != 'N/A'
            "
            :target="'btnIconDownload' + index"
            triggers="hover"
            placement="bottom"
            variant="secondary"
            class="tooltipResults"
            boundary="window"
          >
            Download de registros parciais processados com sucesso
          </b-tooltip>
          <b-tooltip
            v-if="
              item.texto_status == 'Terminado' &&
              item.link_temporario_sucesso != 'N/A'
            "
            :target="'btnIconDownload' + index"
            triggers="hover"
            placement="bottom"
            variant="secondary"
            class="tooltipResults"
            boundary="window"
          >
            Download de registros finais processados com sucesso
          </b-tooltip>
          <button
            v-if="
              item.qtde_linhas_erro != 0 && item.texto_status == 'Terminado'
            "
            :id="'btnIconError' + index"
            class="btnStatus"
          >
            <span
              class="material-symbols-outlined colorRed iconAlign"
              @click="goLink(item.link_temporario_erros)"
              >file_download</span
            >
          </button>
          <b-tooltip
            v-if="
              item.qtde_linhas_erro != 0 && item.texto_status == 'Terminado'
            "
            :target="'btnIconError' + index"
            triggers="hover"
            placement="bottom"
            variant="secondary"
            boundary="window"
          >
            Download de registros finais processados com erro
          </b-tooltip>
          <span :id="'btnIconIntregr' + index">
            <button
              v-if="
                item.texto_status == 'Terminado' ||
                item.texto_status == 'Iniciado' ||
                item.texto_status == 'Pausado' ||
                item.texto_status == 'Criado'
              "
              class="btnStatus skynet"
              :class="{ btnDisabled: disableIntegracao }"
              @click="goInteg"
              v-show="!disableCancel"
              :disabled="disableIntegracao"
            >
              <span class="material-icons iconIntegr iconAlign"
                >room_preferences</span
              >
            </button>
          </span>
          <b-tooltip
            v-if="!disableIntegracao"
            :target="'btnIconIntregr' + index"
            triggers="hover"
            placement="bottom"
            variant="secondary"
            boundary="window"
          >
            Configurar integrações com Skynet para este Lote
          </b-tooltip>
          <b-tooltip
            v-if="disableIntegracao"
            :target="'btnIconIntregr' + index"
            triggers="hover"
            placement="bottom"
            variant="secondary"
            boundary="window"
          >
            Aguarde a finalização das execuções em andamento para configurar
            novas integrações para este Lote
          </b-tooltip>
        </div>
      </td>
      <td class="text-center rowResult">
        <div class="linkLote">
          <span :id="'btnExpandir' + index">
            <b-button
              class="btnStatus skynet"
              v-b-toggle="[`collapse${index}`]"
              variant="light"
              @click="liberaCollapse = !liberaCollapse"
              :disabled="disableCollapse"
            >
              <span
                class="material-symbols-outlined iconIntegr iconAlign"
                :class="{ disableArrow: disableCollapse }"
                v-if="!liberaCollapse"
              >
                expand_more
              </span>
              <span
                class="material-symbols-outlined iconIntegr iconAlign"
                v-if="liberaCollapse"
              >
                expand_less
              </span>
            </b-button>
          </span>

          <b-tooltip
            v-if="!disableCollapse"
            :target="'btnExpandir' + index"
            triggers="hover"
            placement="bottom"
            variant="secondary"
            boundary="window"
          >
            Exibir Integrações para este Lote
          </b-tooltip>
          <b-tooltip
            v-if="disableCollapse && !waitingExec"
            :target="'btnExpandir' + index"
            triggers="hover"
            placement="bottom"
            variant="secondary"
            boundary="window"
          >
            Nenhuma Integração executada.
          </b-tooltip>
          <b-tooltip
            v-if="disableCollapse && waitingExec"
            :target="'btnExpandir' + index"
            triggers="hover"
            placement="bottom"
            variant="secondary"
            boundary="window"
          >
            Aguardando finalização da execução do Lote
          </b-tooltip>
        </div>
      </td>
      <div>
        <span class="tooltip__content" :class="{ show: flag }">Copiado!</span>
      </div>
      <div v-if="liberaSpinner">
        <Loading class="spinner" />
      </div>
    </tr>

    <transition name="smooth" mode="out-in">
      <tr class="collapseLine" v-if="liberaCollapse">
        <td colspan="10" class="collapseCol">
          <div class="collapseBox">
          <ResultadoHistoricoSkynet
              :usuario="usuario"
              :index="index"
              :execucoesIntegracao="execucoesIntegracao"
            />
          </div>
        </td>
      </tr>
    </transition>
  </tbody>
</template>

<script>
import { consultasService } from "@/services";
import { historicoService } from "@/services";
import { lotesStore } from "@/stores/lotes";
import { mapWritableState } from "pinia";
import { skynetService } from "@/services";
import Loading from "@/components/Loading";
import ResultadoHistoricoSkynet from "@/components/Historico/ResultadoHistoricoSkynet.vue";

import Swal from "sweetalert2";

export default {
  name: "LinhasLotes",
  components: { Loading, ResultadoHistoricoSkynet },
  props: {
    tableDataLote: Array,
    fields: Array,
    index: Number,
    item: Object,
    usuario: Object,
    linhasAExecutar: Number,
  },

  data() {
    return {
      refreshValues: [],
      pessoaFisica: "Pessoa Física",
      pessoaJuridica: "Pessoa Jurídica",
      fastBacktest: "Fast Backtest",
      custom: "Custom",
      flag: false,
      confirm: false,
      disableCancel: false,
      liberaSpinner: false,
      counter: 0,
      counterSkynet: 0,
      linhasTotaisArquivo: 0,
      limiteExecucaoDisponivel: 0,
      limiteExecucaoUtilizado: 0,
      liberaCollapse: false,
      execucoesIntegracao: null,
      disableCollapse: true,
      waitingExec: false,
      disableIntegracao: false,
    };
  },

  methods: {
    copyInfo() {
      this.flag = true;
      setTimeout(() => {
        this.flag = false;
      }, 1000);
    },

    goLink(link) {
      window.location.href = link;
    },

    formatarNumero(numero) {
      const numeroString = numero.toString();
      const partes = numeroString.split(".");
      partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return partes.join(".");
    },

    async recuperaIntegracao(identific) {
      this.liberaSpinner = true;
      this.disableIntegracao = true;

      const objetoHistorico = {
        identificacaoExecucao: identific,
      };
      const query = new URLSearchParams(objetoHistorico);
      this.execucoesIntegracao = await skynetService.recuperaDadosIntegracao(
        query.toString()
      );
      this.disableIntegracao = false;
      for (let i in this.execucoesIntegracao) {
        if (this.execucoesIntegracao[i].status == "Submetido") {
          this.recuperaExecucaoSkynet(this.execucoesIntegracao[i]);
          this.disableCollapse = false;
          this.waitingExec = false;
        }
        if (this.execucoesIntegracao[i].status == "Aguardando") {
          this.disableCollapse = true;
          this.waitingExec = true;
        }
      }
      this.liberaSpinner = false;
    },

    async recuperaExecucaoSkynet(item) {
      let periodoDefault = 30;
      const objetoHistorico = {
        hashExecucao: item.hash_execucao_skynet,
        periodoEmDias: periodoDefault,
      };
      const query = new URLSearchParams(objetoHistorico);

      this.execucoesSkynet = await skynetService.recuperaExecucoesSkynet(
        query.toString()
      );
      if (
        this.execucoesSkynet != null &&
        this.execucoesSkynet[0].Status != "FinalizadoComSucesso" &&
        this.execucoesSkynet[0].Status != "FinalizadoComErro" &&
        this.execucoesSkynet[0].Status != "EmCancelamento" &&
        this.execucoesSkynet[0].Status != "Cancelado"
      ) {
        this.disableIntegracao = true;
      }
    },

    async cancelFile(identific) {
      var objetoConsulta = {};
      objetoConsulta["identificacaoExecucao"] = identific;
      this.disableCancel = true;

      try {
        this.cancelamentoLote = await consultasService.cancelaLote(
          objetoConsulta
        );
        this.confirmAlert(true);
      } catch (error) {
        this.confirmAlert(false);
        this.errorAlert = error.toString();
        this.disableCancel = false;
      }
    },

    async showAlert(identific) {
      Swal.fire({
        icon: "warning",
        html:
          "<font size='5'>Deseja cancelar o Lote </br>" +
          "<b>" +
          identific +
          "</b>" +
          "?</font>",
        // width: "35%",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<i class="fas fa-check-circle"></i> Sim!',
        confirmButtonAriaLabel: "Sim!",
        cancelButtonText: '<i class="fas fa-ban"></i> Não',
        cancelButtonAriaLabel: "Não",
        confirmButtonColor: "#2196f3",
        focusConfirm: false,
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.cancelFile(identific);
        }
      });
    },

    confirmAlert(sucess) {
      if (sucess) {
        Swal.fire({
          icon: "success",
          title: "Cancelado com sucesso!!",
          showConfirmButton: false,
          timer: 5000,
          didClose: () => {
            this.refreshValuesRows();
            this.disableCancel = true;
            this.liberaSpinner = false;
          },
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Ops, ocorreu um erro.",
          showConfirmButton: false,
          timer: 3000,
          didClose: () => {
            this.refreshValuesRows();
            this.disableCancel = true;
            this.liberaSpinner = false;
          },
        });
      }
    },

    async startLote(identific) {
      if (this.limiteExecucaoUtilizado < 1) {
        var objetoConsulta = {};
        objetoConsulta["identificacaoExecucao"] = identific;
        this.disableCancel = true;
        this.liberaSpinner = true;

        try {
          this.execucaoLote = await consultasService.iniciaLote(objetoConsulta);
          this.refreshValuesRows();
          this.startAlert(true);
        } catch (error) {
          this.confirmAlert(false);
          this.errorAlertExec = error.toString();
        }
      }
    },

    async pauseFile(identific) {
      var objetoConsulta = {};
      objetoConsulta["identificacaoExecucao"] = identific;
      this.disableCancel = true;
      this.liberaSpinner = true;

      try {
        this.pausandoLote = await consultasService.pausaLote(objetoConsulta);
        this.refreshValuesRows();
        this.pauselert(true);
      } catch (error) {
        this.confirmAlert(false);
        this.errorAlertPause = error.toString();
      }
    },

    async showAlertPause(identific) {
      Swal.fire({
        icon: "question",
        html:
          "<font size='5'>Deseja pausar o Lote </br>" +
          "<b>" +
          identific +
          "</b>" +
          "?</font>",
        // width: "35%",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<i class="fas fa-check-circle"></i> Sim!',
        confirmButtonAriaLabel: "Sim!",
        cancelButtonText: '<i class="fas fa-ban"></i> Não',
        cancelButtonAriaLabel: "Não",
        confirmButtonColor: "#2196f3",
        focusConfirm: false,
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.pauseFile(identific);
        }
      });
    },

    pauselert(sucess) {
      if (sucess) {
        Swal.fire({
          icon: "success",
          title: "Lote pausado!",
          showConfirmButton: false,
          timer: 5000,
          didOpen: () => {
            this.refreshValuesRows();
          },
          didClose: () => {
            this.refreshValuesRows();
            this.disableCancel = false;
            this.liberaSpinner = false;
          },
        });
      }
    },
    startAlert(sucess) {
      if (sucess) {
        Swal.fire({
          icon: "success",
          title: "Lote iniciado!",
          showConfirmButton: false,
          timer: 5000,
          didOpen: () => {
            this.refreshValuesRows();
          },
          didClose: () => {
            this.refreshValuesRows();
            this.disableCancel = false;
            this.liberaSpinner = false;
          },
        });
      }
    },

    refreshRow() {
      if (
        this.item.texto_status != "Terminado" &&
        this.item.texto_status != "Cancelado"
      ) {
        this.refreshValuesRows();
      } else {
        clearInterval(this.counter);
      }
    },

    async refreshValuesRows() {
      const objetoHistorico = {
        identificacao_execucao: this.item.identificacao_execucao,
      };
      const query = new URLSearchParams(objetoHistorico);
      this.refreshValues = await historicoService.recuperaDadosHistoricoLotes(
        query.toString()
      );
      this.item.qtde_linhas_processadas =
        this.refreshValues[0].qtde_linhas_processadas;
      this.item.qtde_linhas_erro = this.refreshValues[0].qtde_linhas_erro;
      this.item.texto_status = this.refreshValues[0].texto_status;
      this.item.pct_completo = this.refreshValues[0].pct_completo;
      this.item.tempo_estimado = this.refreshValues[0].tempo_estimado;
      this.item.link_temporario_erros =
        this.refreshValues[0].link_temporario_erros;
      this.item.link_temporario_sucesso =
        this.refreshValues[0].link_temporario_sucesso;
    },

    calculaLimiteExecucao() {
      this.linhasTotaisArquivo =
        this.item.qtde_linhas_total - this.item.qtde_linhas_processadas;

      this.limiteExecucaoDisponivel =
        this.usuario.LimiteDeConsultas -
        this.usuario.ConsultasAtual -
        this.linhasAExecutar -
        this.linhasTotaisArquivo;

      this.limiteExecucaoUtilizado =
        1 - this.limiteExecucaoDisponivel / this.usuario.LimiteDeConsultas;
    },

    goInteg() {
      this.$router.push(
        "/integracoes_skynet/" + this.item.identificacao_execucao + "/0"
      );
    },
  },

  mounted() {
    // if (this.item.identificacao_execucao != "") {
    //   this.counterSkynet = setInterval(() => {
    //   }, 5000);
    // }

    if (
      this.item.texto_status != "Terminado" &&
      this.item.texto_status != "Cancelado"
    ) {
      this.counter = setInterval(this.refreshRow, 3000);
    }
    this.recuperaIntegracao(this.item.identificacao_execucao);
  },

  beforeDestroy() {
    clearInterval(this.counter);
    clearInterval(this.counterSkynet);
  },

  created() {
    this.calculaLimiteExecucao();
  },

  computed: {
    ...mapWritableState(lotesStore, {
      historicoFiltrosLotes: "historicoFiltrosLotes",
      historicoResultadosLotes: "historicoResultadosLotes",
    }),

    tipoConsulta() {
      let tipo = "";
      if (this.item.produto == "v3/box") {
        tipo = "Box de Atributos";
      }
      if (this.item.produto == "v3/modelo") {
        tipo = "Box de Modelos";
      }
      return tipo;
    },
  },
};
</script>

<style lang="scss" scoped>
.rowResult {
  width: 15%;
  max-height: 70px;
  vertical-align: middle;
  cursor: default;
  border: none;
  line-height: 16px;
  padding: 10px;

  &.actions {
    min-width: 165px;
  }

  &.proj {
    cursor: pointer;
    word-break: break-all;
    width: 25%;
  }
}
.action {
  width: 10% !important;
}

.btnIcon {
  display: flex;
  align-items: center;
  height: 35px;
}

.tooltip__content {
  background: #616161;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
  font-size: 12px;
  padding: 5px 8px;
  position: fixed;
  left: 18rem;
  bottom: 3%;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  opacity: 0;
  z-index: 99;
  @include mediaQueryMax(medium) {
    left: 0.9rem;
  }
}

.tooltip__content.show {
  opacity: 1;
  transition: 1s;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.selectIcon {
  display: inline-block;
  text-align: center;
}
.statusHist {
  font-weight: 500;
  margin-bottom: 1px !important;
  text-align: center !important;
}

.btnIcon {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-inline: 3px;
}

.btnIcon:hover {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 20%;
}

.btnIcon:active {
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.1);
}

.btnStatus {
  background-color: transparent;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  padding: 0%;
  margin: 0 3px;
  width: 30px;
  height: 30px;
  /* display: block; */
  text-align: center !important;
  align-content: center !important;
  align-items: center !important;
  align-self: center !important;
}

.btnStatus:hover {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 25%;
}

.btnStatus:active {
  box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.1);
}

.btnDisabled {
  opacity: 0.4;
}

.linkLote {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
}

.iconLote {
  display: inline-block;
  text-align: center;
}

.iconAlign {
  display: flex;
  align-items: center;
  justify-content: center;
}
.disableArrow {
  color: #b8b8b8 !important;
  // color: red !important;
  background-color: transparent;
}

.iconIntegr {
  color: #374567;
  background-color: transparent;
}

.spinner {
  width: 20px;
  position: fixed;
  left: 50%;
  top: 50%;
}

.collapseLine {
  box-shadow: 1px 0 10px #aaa;
  max-height: 70px;
}
.collapseBox {
  min-height: 70px;
  display: flex;
  margin: 5px;
  padding: 0;
}

.colorYellow {
  color: rgb(247, 169, 0);
}
.colorRed {
  color: #b71c1c;
}
.colorBlue {
  color: #1da0f2;
}
.colorGreen {
  color: #28a745;
}
.progress-box {
  position: relative;
  background-color: #acacac30;
  height: 25px;
  border-radius: 5px;
  width: 100%;
  display: flex;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  width: 100%;
  // overflow: hidden;
  &.green {
    background-color: #10b981;
  }
  &.blue {
    background-color: #1da0f2;
  }
  &.red {
    background-color: #b71c1c;
  }

  &.canceled {
    background-color: #b8b8b8 !important;
  }
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  transform: translate(-50%, -50%);
}
</style>
