var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card arquivos"},[_c('div',{staticClass:"row-card arquivos"},[_c('span',{staticClass:"titulo titulo-box"},[_vm._v(" Arquivos de entrada ")]),(
        _vm.arquivosEntradaProjeto.length == 0 &&
        _vm.arquivosExcluidosProjeto.length == 0
      )?_c('span',{staticClass:"resumo-status sumario"},[_vm._v(" Nenhum arquivo adicionado ")]):_vm._e(),(
        _vm.arquivosEntradaProjeto.length != 0 ||
        _vm.arquivosExcluidosProjeto.length != 0
      )?_c('span',{staticClass:"resumo-status sumario"},[_vm._v(" "+_vm._s(_vm.resumoStatus)+" ")]):_vm._e(),(!_vm.abreCard)?_c('span',{staticClass:"material-symbols-outlined icone-uploads",class:{
        disab:
          _vm.arquivosEntradaProjeto.length == 0 &&
          _vm.arquivosExcluidosProjeto.length == 0,
      },on:{"click":function($event){_vm.abreCard = !_vm.abreCard}}},[_vm._v(" expand_more ")]):_vm._e(),(_vm.abreCard)?_c('span',{staticClass:"material-symbols-outlined icone-uploads",on:{"click":function($event){_vm.abreCard = !_vm.abreCard}}},[_vm._v(" expand_less ")]):_vm._e()]),_c('transition-group',{staticClass:"projetos-container",attrs:{"name":"expand","tag":"div"}},[(_vm.abreCard)?_c('div',{key:"menu",staticClass:"menu-tabela"},[_c('div',{staticClass:"projetos-menu"},_vm._l((_vm.menus),function(menu,index){return _c('div',{key:index,staticClass:"menu-tab"},[_c('span',{staticClass:"item-tab",class:{ selecionado: _vm.menuSelecionado.Status == menu.Status },on:{"click":function($event){return _vm.alteraMenu(menu, index)}}},[_vm._v(" "+_vm._s(menu.Status)+" ")])])}),0),_c('div',{staticClass:"info-tabela"},[_c('span',{staticClass:"input-pesquisa"},[_c('md-field',{staticClass:"field-pesquisar"},[_c('label',{staticStyle:{"padding":"0 2px"},attrs:{"for":"pesquisaProdutos"}},[_vm._v(" Pesquisar por nome do arquivo ")]),_c('md-input',{attrs:{"id":"pesquisaProdutos"},model:{value:(_vm.pesquisaNome),callback:function ($$v) {_vm.pesquisaNome=$$v},expression:"pesquisaNome"}})],1)],1),_c('span',{staticClass:"info-tamanho"},[_vm._v(" Espaço utilizado: "+_vm._s(_vm.formatarTamanhoArquivo(_vm.menuSelecionado.Tamanho))+" ")])])]):_vm._e(),(_vm.abreCard)?_c('div',{key:"card",staticClass:"sub-linha tabela"},[(_vm.liberaTabela)?_c('div',{staticClass:"tabela-header"},_vm._l((_vm.header),function(cabecalho,i){return _c('div',{key:cabecalho,staticClass:"cabecalho",class:{ 'nome-arquivo': i == 1 }},[_vm._v(" "+_vm._s(cabecalho)+" "),_c('span',{staticClass:"box-ord"},[(i !== _vm.header.length - 1)?_c('span',{staticClass:"material-symbols-outlined icone-ordenacao",class:{
                active: _vm.ordemAtual === i,
                // asc: ordemAtual === i && ordemTipo === 'asc',
                // desc: ordemAtual === i && ordemTipo === 'desc',
              },on:{"click":function($event){return _vm.ordenar(
                  i,
                  _vm.ordemTipo === 'asc' && _vm.ordemAtual === i ? 'desc' : 'asc'
                )}}},[_vm._v(" "+_vm._s(_vm.ordemTipo === "asc" && _vm.ordemAtual === i ? "arrow_drop_up" : "arrow_drop_down")+" ")]):_vm._e()])])}),0):_vm._e(),(_vm.menuSelecionado.Tamanho == 0)?_c('div',{staticClass:"md-card aviso"},[_vm._v(" Nenhum arquivo adicionado ")]):_vm._e(),(!_vm.liberaTabela && _vm.menuSelecionado.Tamanho != 0)?_c('div',{staticClass:"md-card aviso"},[_vm._v(" Nenhum arquivo encontrado ")]):_vm._e(),(
          _vm.menuSelecionado.Status == 'Ativos' && _vm.menuSelecionado.Quantidade > 0
        )?_c('ArquivosEntradaAtivos',{attrs:{"guidProjeto":_vm.guidProjeto,"objetoProjeto":_vm.objetoProjeto,"metadadosProjeto":_vm.metadadosProjeto,"arquivosEntradaProjeto":_vm.arquivosEntradaProjetoFiltrada,"carregandoDownload":_vm.carregandoDownload},on:{"recuperaProjeto":_vm.recuperaProjeto,"downloadArquivosSimples":_vm.downloadArquivosSimples,"downloadArquivosStreaming":_vm.downloadArquivosStreaming,"cancelarDownload":_vm.cancelarDownload}}):_vm._e(),(
          _vm.menuSelecionado.Status == 'Excluídos' &&
          _vm.menuSelecionado.Quantidade > 0
        )?_c('ArquivosExcluidos',{attrs:{"guidProjeto":_vm.guidProjeto,"objetoProjeto":_vm.objetoProjeto,"metadadosProjeto":_vm.metadadosProjeto,"arquivosExcluidosProjeto":_vm.arquivosExcluidosProjetoFiltrada},on:{"recuperaProjeto":_vm.recuperaProjeto}}):_vm._e()],1):_vm._e()]),(_vm.liberaLoading)?_c('Loading',{staticClass:"spinner"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }