<template>
  <div class="card arquivos">
    <div class="row-card arquivos">
      <span
        class="resumo-status sumario"
        v-if="metadadosProjeto.SumarioDiretorioSaida.QtdArquivosAtivos != 0"
      >
        {{ metadadosProjeto.SumarioDiretorioSaida.QtdArquivosAtivos }}
        arquivo(s) de saída ativo(s)
        <span
          v-if="metadadosProjeto.SumarioDiretorioSaida.QtdArquivosExcluidos > 0"
        >
          e
          {{ metadadosProjeto.SumarioDiretorioSaida.QtdArquivosExcluidos }}
          arquivos excluídos
        </span>
      </span>
      <span class="resumo-status sumario" v-else>
        Nenhum arquivo adicionado
      </span>
      <span class="titulo titulo-box"> Arquivos de saída </span>
      <span
        class="resumo-status espaco"
        v-if="metadadosProjeto.SumarioDiretorioSaida.TamanhoArquivosAtivos > 0"
      >
        Espaço utilizado:
        {{
          formatarTamanhoArquivo(
            metadadosProjeto.SumarioDiretorioSaida.TamanhoArquivosAtivos
          )
        }}
      </span>
      <span
        class="material-symbols-outlined icone-uploads"
        v-if="!abreCard"
        :class="{ disab: arquivosSaidaProjeto.length == 0 }"
        @click="abreCard = !abreCard"
      >
        expand_more
      </span>
      <span
        class="material-symbols-outlined icone-uploads"
        v-if="abreCard"
        @click="abreCard = !abreCard"
      >
        expand_less
      </span>
    </div>
    <transition name="expand">
      <div class="sub-linha tabela" v-if="abreCard">
        <div class="tabela-header">
          <div
            class="cabecalho"
            v-for="(cabecalho, i) in header"
            :key="cabecalho"
            :class="{ 'nome-arquivo': i == 1 }"
          >
            {{ cabecalho }}
          </div>
        </div>
        <div class="tabela-body">
          <div
            class="sub-linhas"
            v-for="file in arquivosSaidaProjeto"
            :key="file.NomeArquivo"
          >
            <div class="sub-item data-arquivo">
              {{ formatarData(file.DataCriacao) }}
              <span class="desc" v-if="file.ExpiraEm != null">
                Expira em:
                {{ formatarData(file.ExpiraEm) }}
              </span>
            </div>
            <div class="sub-item nome-arquivo">
              {{ file.NomeArquivo.replace("saida/", "") }}
            </div>
            <div class="sub-item">
              {{ formatarTamanhoArquivo(file.Tamanho) }}
            </div>
            <div class="sub-item">
              {{ file.Tipo }}
              <span v-if="file.Tipo">/</span>
              {{ file.Extensao }}
            </div>
            <!-- <div class="sub-item"></div>
            <div class="sub-item"></div> -->
          </div>
        </div>
      </div>
    </transition>
  </div>

  <!-- <div v-else class="card arquivos aviso">Nenhum arquivo adicionado</div> -->
</template>

<script>
import { gestorProjetosService } from "@/services";
import { userPreferenceStore } from "@/stores/userPreference";
import { mapWritableState, mapActions } from "pinia";
import Loading from "@/components/Loading";
import JSZip from "jszip";

export default {
  name: "ArquivosDeSaida",

  components: {},

  props: {
    guidProjeto: String,
    objetoProjeto: Object,
    metadadosProjeto: Object,
    arquivosSaidaProjeto: Array,
  },

  data() {
    return {
      header: [
        "Data",
        "Nome do arquivo",
        "Tamanho do arquivo",
        "Tipo/Extensão",
        // "Status",
        // "Ações",
      ],

      abreCard: true,
    };
  },

  watch: {
    arquivosSaidaProjeto() {
      if (this.arquivosSaidaProjeto.length == 0) {
        this.abreCard = false;
      }
    },
  },

  methods: {
    formatarData(dataStr) {
      if (dataStr) {
        const data = new Date(dataStr);

        const dia = String(data.getUTCDate()).padStart(2, "0");
        const mes = String(data.getUTCMonth() + 1).padStart(2, "0");
        const ano = data.getUTCFullYear();

        const horas = String(data.getUTCHours()).padStart(2, "0");
        const minutos = String(data.getUTCMinutes()).padStart(2, "0");
        const segundos = String(data.getUTCSeconds()).padStart(2, "0");

        return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
      }
      return dataStr;
    },

    formatarTamanhoArquivo(tamanho) {
      if (tamanho < 1024) {
        return tamanho + " B";
      } else if (tamanho < 1024 * 1024) {
        return (tamanho / 1024).toFixed(2) + " KB";
      } else if (tamanho < 1024 * 1024 * 1024) {
        return (tamanho / (1024 * 1024)).toFixed(2) + " MB";
      } else {
        return (tamanho / (1024 * 1024 * 1024)).toFixed(2) + " GB";
      }
    },

    dataHoraAtual() {
      const agora = new Date();
      const dia = String(agora.getDate()).padStart(2, "0");
      const mes = String(agora.getMonth() + 1).padStart(2, "0");
      const ano = agora.getFullYear();
      const horas = String(agora.getHours()).padStart(2, "0");
      const minutos = String(agora.getMinutes()).padStart(2, "0");
      const segundos = String(agora.getSeconds()).padStart(2, "0");

      return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
    },
  },

  computed: {},

  async mounted() {
    if (this.arquivosSaidaProjeto.length == 0) {
      this.abreCard = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  box-shadow: none;
  border-radius: 5px;
  border: none;
  gap: 5px;
}

.row-card {
  display: flex;
  flex-direction: row;
  width: 100%;

  &.arquivos {
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    color: #0070c0;
  }

  &.aviso {
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    padding: 0 15px;
  }
}

.titulo-box {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  border-bottom: 1px solid #0070c0;
  padding: 5px 10px;
  margin-bottom: 5px;
}

.resumo-status {
  position: absolute;
  font-size: 12px;
  color: black;
  font-weight: 300;
  line-height: 14px;
  top: 25px;
  cursor: default;

  &.sumario {
    left: 300px;
  }
  &.espaco {
    right: 80px;
  }
}

.icone-uploads {
  cursor: pointer;
  position: absolute;
  right: 25px;

  &.disab {
    cursor: default !important;
    pointer-events: none;
    color: #afafaf;
  }
}

.titulo {
  font-weight: 400;
  cursor: default;

  &.data {
    margin: 10px 0;
  }
}

.texto {
  font-weight: 300;
}

.desc {
  font-size: 12px;
  font-weight: 300;
}

.sub-linhas {
  display: flex;
  width: 100%;
  background-color: #ececec;
  border-bottom: 1px solid gray;
  min-height: 50px;

  &:hover {
    background-color: #e0e0e0;
  }
}

.sub-linha {
  width: calc(100%);
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  max-height: 400px;
  overflow-y: auto;
}

.tabela-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 0 5px;

  .cabecalho {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-weight: 600;
    flex: 1;
    cursor: default;
  }
}

.tabela-body {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.sub-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  cursor: default;
  text-align: center;
  word-break: break-all;
  flex: 1;
  padding: 0 5px;

  &.data-arquivo {
    flex-direction: column;
    gap: 5px;
  }

  &.acoes {
    gap: 10px;
  }
}

.icon-action {
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
}

.icone-deletar {
  transition: 0.3s;
  cursor: pointer;
  color: #b71c1c;

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
}

.fundo-verde {
  background-color: #10b981;
  color: white;
}
.fundo-amarelo {
  background-color: #f6f88d;
  color: black;
}
.fundo-vermelho {
  background-color: #b71c1c;
  color: white;
}

.disabled {
  cursor: default !important;
  pointer-events: none;
  color: white;
  opacity: 0.7;
}

.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.5s ease, opacity 0.5s ease;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
  opacity: 0;
}

.expand-enter-to {
  max-height: 400px;
  opacity: 1;
}
</style>
