<template>
  <div style="width: 100%">
    <div class="tabela-body">
      <div
        class="sub-linhas"
        v-for="(file, index) in arquivosEntradaProjeto"
        :key="file.NomeArquivo"
      >
        <div class="sub-item nome-arquivo">
          {{ formatarData(file.DataCriacaoArquivo) }}
        </div>
        <div class="sub-item nome-arquivo">
          <span v-if="file.DataExpiracaoArquivo">
            {{ formatarData(file.DataExpiracaoArquivo) }}
          </span>
          <span class="desc" v-else> Sem expiração </span>
        </div>
        <div class="sub-item nome-arquivo">
          {{ file.NomeArquivo }}
        </div>
        <div class="sub-item">
          {{ formatarTamanhoArquivo(file.Tamanho) }}
        </div>
        <div class="sub-item">
          {{ file.TipoArquivo }}
          <span v-if="file.TipoArquivo">/</span>
          {{ file.Extensao }}
        </div>
        <div class="sub-item acoes" v-if="carregandoDownload[file.NomeArquivo]">
          <span class="progr-fundo">
            <span
              class="progr-contagem"
              :class="{ completo: file.Progresso == 100 }"
              :style="{ width: file.Progresso + '%' }"
            >
            </span>
            <div class="progr-texto" :class="{ branco: file.Progresso > 55 }">
              {{ file.Progresso }}%
            </div>
          </span>
          <span
            class="material-symbols-outlined icone-action cor-vermelho"
            @click="cancelarDownloadSimples(file, index)"
            :id="'botao-cancelar-download' + index"
          >
            block
          </span>
          <b-tooltip
            :target="'botao-cancelar-download' + index"
            triggers="hover"
            placement="bottom"
            variant="secondary"
            class="tooltip-acoes"
            boundary="window"
          >
            Cancelar Donwload
          </b-tooltip>
        </div>
        <div
          class="sub-item acoes"
          v-if="!carregandoDownload[file.NomeArquivo]"
        >
          <span
            class="material-symbols-outlined icone-action download cor-azul"
            :id="'download-simples' + index"
            :class="{ disabled: carregandoDownload[file.NomeArquivo] }"
            @click="downloadArquivosSimples(file, index)"
          >
            cloud_download
          </span>
          <span
            class="material-symbols-outlined icone-action cor-vermelho"
            @click="alertaDeletar(file)"
            :class="{ disabled: carregandoDownload[file.NomeArquivo] }"
            :id="'botao-deletar' + index"
          >
            delete
          </span>
          <b-tooltip
            v-for="(tooltip, id) in tooltips"
            :key="id + index"
            :target="id + index"
            triggers="hover"
            placement="bottom"
            variant="secondary"
            class="tooltip-acoes"
            boundary="window"
          >
            {{ tooltip }}
          </b-tooltip>
        </div>
      </div>
    </div>
    <span
      class="aviso-box fundo-amarelo"
      v-if="Object.values(carregandoDownload).some((value) => value === true)"
    >
      *Os arquivos estão sendo baixados. Se o navegador for fechado antes do
      término o download será cancelado.
    </span>
  </div>
</template>

<script>
import { gestorProjetosService } from "@/services";
import { gestorArquivosService } from "@/services";
import { userPreferenceStore } from "@/stores/userPreference";
import { mapWritableState, mapActions } from "pinia";
import JSZip from "jszip";
import Swal from "sweetalert2";

export default {
  name: "ArquivosDeEntradaAtivos",

  components: {},

  props: {
    guidProjeto: String,
    objetoProjeto: Object,
    metadadosProjeto: Object,
    carregandoDownload: Object,

    arquivosEntradaProjeto: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      tooltips: {
        "download-simples": "Download do arquivo",
        "botao-deletar": "Deletar arquivo",
      },
    };
  },

  watch: {},

  methods: {
    async deletaArquivo(file) {
      var objetoConsulta = {};
      objetoConsulta["IdentificadorProjeto"] = this.guidProjeto;
      objetoConsulta["IdentificadorArquivo"] = file.IdentificadorArquivo;

      try {
        const resultado = await gestorArquivosService.removeArquivo(objetoConsulta);
        this.confirmAlert(true);
      } catch (error) {
        this.confirmAlert(false);
        throw error;
      }
    },

    async cancelarDownloadSimples(file, index) {
      this.$root.$emit("bv::hide::tooltip", "botao-cancelar-download" + index);
      this.$emit("cancelarDownload", file, index);
    },

    async downloadArquivosSimples(file, index) {
      this.$root.$emit("bv::hide::tooltip", "download-simples" + index);
      this.$emit("downloadArquivosSimples", file, index);
    },

    async downloadArquivosStreaming(file, index) {
      this.$root.$emit("bv::hide::tooltip", "download-streaming" + index);
      this.$emit("downloadArquivosStreaming", file, index);
    },

    formatarData(dataStr) {
      if (dataStr) {
        const data = new Date(dataStr);

        const dia = String(data.getUTCDate()).padStart(2, "0");
        const mes = String(data.getUTCMonth() + 1).padStart(2, "0");
        const ano = data.getUTCFullYear();

        const horas = String(data.getUTCHours()).padStart(2, "0");
        const minutos = String(data.getUTCMinutes()).padStart(2, "0");
        const segundos = String(data.getUTCSeconds()).padStart(2, "0");

        return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
      }
      return dataStr;
    },

    formatarTamanhoArquivo(tamanho) {
      if (tamanho < 1024) {
        return tamanho + " B";
      } else if (tamanho < 1024 * 1024) {
        return (tamanho / 1024).toFixed(2) + " KB";
      } else if (tamanho < 1024 * 1024 * 1024) {
        return (tamanho / (1024 * 1024)).toFixed(2) + " MB";
      } else {
        return (tamanho / (1024 * 1024 * 1024)).toFixed(2) + " GB";
      }
    },

    dataHoraAtual() {
      const agora = new Date();
      const dia = String(agora.getDate()).padStart(2, "0");
      const mes = String(agora.getMonth() + 1).padStart(2, "0");
      const ano = agora.getFullYear();
      const horas = String(agora.getHours()).padStart(2, "0");
      const minutos = String(agora.getMinutes()).padStart(2, "0");
      const segundos = String(agora.getSeconds()).padStart(2, "0");

      return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
    },

    async alertaDeletar(file) {
      Swal.fire({
        icon: "warning",
        html:
          "<font size='5'>Deseja deletar o arquivo </br>" +
          "<b>" +
          file.NomeArquivo +
          "</b>" +
          "?</font>",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<i class="fas fa-check-circle"></i> Sim!',
        confirmButtonAriaLabel: "Sim!",
        cancelButtonText: '<i class="fas fa-ban"></i> Não',
        cancelButtonAriaLabel: "Não",
        confirmButtonColor: "#2196f3",
        focusConfirm: false,
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deletaArquivo(file);
        }
      });
    },

    confirmAlert(sucess) {
      if (sucess) {
        Swal.fire({
          icon: "success",
          title: "Ação realizada com sucesso!",
          showConfirmButton: false,
          timer: 2000,
          didOpen: () => {
            this.$emit("recuperaProjeto");
          },
          didClose: () => {},
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Ops, ocorreu um erro.",
          showConfirmButton: false,
          timer: 2000,
          didClose: () => {},
        });
      }
    },
  },

  computed: {},

  async mounted() {},
};
</script>

<style lang="scss" scoped>
.desc {
  font-size: 12px;
  font-weight: 300;
}

.sub-linhas {
  display: flex;
  width: 100%;
  background-color: #ececec;
  border-bottom: 1px solid gray;
  min-height: 50px;

  &:hover {
    background-color: #e0e0e0;
  }
}

.tabela-body {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.sub-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 14px;
  font-weight: 400;
  cursor: default;
  text-align: center;
  word-break: break-all;
  flex: 1;
  padding: 0 5px;

  &.barra-prog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.nome-arquivo {
    width: 30%;
  }

  &.acoes {
    gap: 10px;
  }
}

.progr-fundo {
  border: 1px solid #eeeeee;
  background-color: white;
  height: 18px;
  width: 100px;
  border-radius: 20px;
  overflow: hidden;
}

.progr-contagem {
  background-color: #1da0f2;
  border-radius: 20px 0px 0px 20px;
  height: 100%;
  display: flex;

  &.completo {
    border-radius: 20px;
  }
}

.progr-texto {
  position: relative;
  bottom: 18px;
  z-index: 10;
  height: 1px;
  line-height: 16px;
  color: rgb(75, 75, 75);

  &.branco {
    color: white;
  }
}

.icone-action {
  transition: 0.3s;
  cursor: pointer;
  font-size: 26px;

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
}

.aviso-box {
  font-size: 14px;
  font-weight: 300;
  line-height: 12px;
  color: white;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 60px);
  height: 35px;
  border-radius: 5px;
  margin: 15px;
  
}

.spinner {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.cor-verde {
  color: #10b981;
}
.cor-amarelo {
  color: orange;
}
.cor-vermelho {
  color: #b71c1c;
}
.cor-azul {
  color: #1da0f2;
}

.fundo-verde {
  background-color: #10b981;
  color: white;
}
.fundo-amarelo {
  background-color: #f6f88d;
  color: black;
}
.fundo-vermelho {
  background-color: #b71c1c;
  color: white;
}

.disabled {
  cursor: default !important;
  pointer-events: none;
  opacity: 0.7;
}
</style>
