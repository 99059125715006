var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',{staticClass:"tableLines"},[(_vm.item.status == 'Submetido' && this.execucoesSkynet != null)?_c('tr',{staticClass:"rowResults"},[_c('td',{staticClass:"text-center rowResult"},[_vm._v(" "+_vm._s(_vm._f("toDataHoraPtbrMenos3h")(this.execucoesSkynet[0].DataInicio))+" ")]),_c('td',{staticClass:"text-center rowResult"},[(_vm.item.produto == 'FastModel')?_c('span',[_vm._v(" Logistic Reg. ")]):_vm._e(),(_vm.item.produto == 'MachineLearning')?_c('span',[_vm._v(" "+_vm._s(_vm.atributosSeparados)+" ")]):_vm._e()]),_c('td',{staticClass:"text-center rowResult"},[_c('span',[_vm._v(" "+_vm._s(_vm.amostraSeparada)+" ")])]),_c('td',{staticClass:"text-center rowResult"},[_c('div',{staticClass:"selectIcon"},[_c('button',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(_vm.item.hash_execucao_skynet),expression:"item.hash_execucao_skynet",arg:"copy"}],staticClass:"btnIcon",attrs:{"id":'btnIconHash' + _vm.index + _vm.indexInt},on:{"click":_vm.copyInfo}},[_c('span',{staticClass:"material-symbols-outlined btnIconInside"},[_vm._v(" tag ")])]),_c('b-tooltip',{staticClass:"tooltipResults",attrs:{"target":'btnIconHash' + _vm.index + _vm.indexInt,"triggers":"hover","placement":"bottom","variant":"secondary","boundary":"window"}},[_vm._v(" "+_vm._s(_vm.item.hash_execucao_skynet)+" ")])],1)]),_c('td',{staticClass:"text-center rowResult",staticStyle:{"width":"21%"}},[_c('b-progress',{attrs:{"id":'btnIconProgress' + _vm.index + _vm.indexInt,"height":"1.5rem"}},[_c('b-progress-bar',{staticClass:"bProgress",class:{
            progressCanceled:
              this.execucoesSkynet[0].Status == 'Cancelado' ||
              this.execucoesSkynet[0].Status == 'EmCancelamento',
            progressEnd:
              this.execucoesSkynet[0].Status == 'FinalizadoComSucesso',
            bProgressError:
              this.execucoesSkynet[0].Status == 'FinalizadoComErro',
          },attrs:{"value":this.execucoesSkynet[0].PercentualEstimado * 100}},[_vm._v(" "+_vm._s((this.execucoesSkynet[0].PercentualEstimado * 100).toFixed(2) + "%")+" ")])],1)],1),_c('td',{staticClass:"text-center rowResult"},[_vm._v(" "+_vm._s(this.execucoesSkynet[0].StatusDetalhe)+" ")]),_c('td',{staticClass:"text-center rowResult"},[_c('div',{staticClass:"linkLote"},[_c('div',{staticClass:"iconLote selectIcon"},[(
              this.execucoesSkynet[0].Status == 'FinalizadoComSucesso' ||
              this.execucoesSkynet[0].Status == 'FinalizadoComErro'
            )?_c('button',{staticClass:"btnStatus",attrs:{"id":'btnIconDownload' + _vm.index + _vm.indexInt}},[_c('a',{attrs:{"href":this.execucoesSkynet[0].LinkTemporarioParaDownload}},[_c('span',{staticClass:"material-symbols-outlined iconAlign",class:{
                  colorBlue:
                    this.execucoesSkynet[0].Status == 'FinalizadoComSucesso',
                  colorRed:
                    this.execucoesSkynet[0].Status == 'FinalizadoComErro',
                }},[_vm._v("file_download")])])]):_vm._e(),(this.execucoesSkynet[0].Status == 'FinalizadoComSucesso')?_c('b-tooltip',{staticClass:"tooltipResults",attrs:{"target":'btnIconDownload' + _vm.index + _vm.indexInt,"triggers":"hover","placement":"bottom","variant":"secondary","boundary":"viewport"}},[_vm._v(" Download Integração ")]):_vm._e(),(this.execucoesSkynet[0].Status == 'FinalizadoComErro')?_c('b-tooltip',{staticClass:"tooltipResults",attrs:{"target":'btnIconDownload' + _vm.index + _vm.indexInt,"triggers":"hover","placement":"bottom","variant":"secondary","boundary":"viewport"}},[_vm._v(" Download Erros ")]):_vm._e()],1)])]),_c('div',[_c('span',{staticClass:"tooltip__content",class:{ show: _vm.flag }},[_vm._v("Copiado!")])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }