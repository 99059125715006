<template>
  <div class="card arquivos">
    <div class="row-card arquivos">
      <span class="titulo titulo-box"> Arquivos de entrada </span>
      <span
        class="resumo-status sumario"
        v-if="
          arquivosEntradaProjeto.length == 0 &&
          arquivosExcluidosProjeto.length == 0
        "
      >
        Nenhum arquivo adicionado
      </span>
      <span
        class="resumo-status sumario"
        v-if="
          arquivosEntradaProjeto.length != 0 ||
          arquivosExcluidosProjeto.length != 0
        "
      >
        {{ resumoStatus }}
      </span>
      <span
        class="material-symbols-outlined icone-uploads"
        v-if="!abreCard"
        :class="{
          disab:
            arquivosEntradaProjeto.length == 0 &&
            arquivosExcluidosProjeto.length == 0,
        }"
        @click="abreCard = !abreCard"
      >
        expand_more
      </span>
      <span
        class="material-symbols-outlined icone-uploads"
        v-if="abreCard"
        @click="abreCard = !abreCard"
      >
        expand_less
      </span>
    </div>
    <transition-group name="expand" tag="div" class="projetos-container">
      <div class="menu-tabela" key="menu" v-if="abreCard">
        <div class="projetos-menu">
          <div class="menu-tab" v-for="(menu, index) in menus" :key="index">
            <span
              class="item-tab"
              :class="{ selecionado: menuSelecionado.Status == menu.Status }"
              @click="alteraMenu(menu, index)"
            >
              {{ menu.Status }}
            </span>
            <!-- <span class="desc"> {{ menu.Quantidade }} arquivo(s) </span> -->
          </div>
        </div>
        <div class="info-tabela">
          <span class="input-pesquisa">
            <md-field class="field-pesquisar">
              <label for="pesquisaProdutos" style="padding: 0 2px">
                Pesquisar por nome do arquivo
              </label>
              <md-input id="pesquisaProdutos" v-model="pesquisaNome"></md-input>
            </md-field>
          </span>
          <span class="info-tamanho">
            Espaço utilizado:
            {{ formatarTamanhoArquivo(menuSelecionado.Tamanho) }}
          </span>
        </div>
      </div>

      <div class="sub-linha tabela" v-if="abreCard" key="card">
        <div class="tabela-header" v-if="liberaTabela">
          <div
            class="cabecalho"
            v-for="(cabecalho, i) in header"
            :key="cabecalho"
            :class="{ 'nome-arquivo': i == 1 }"
          >
            {{ cabecalho }}
            <span class="box-ord">
              <span
                v-if="i !== header.length - 1"
                class="material-symbols-outlined icone-ordenacao"
                :class="{
                  active: ordemAtual === i,
                  // asc: ordemAtual === i && ordemTipo === 'asc',
                  // desc: ordemAtual === i && ordemTipo === 'desc',
                }"
                @click="
                  ordenar(
                    i,
                    ordemTipo === 'asc' && ordemAtual === i ? 'desc' : 'asc'
                  )
                "
              >
                {{
                  ordemTipo === "asc" && ordemAtual === i
                    ? "arrow_drop_up"
                    : "arrow_drop_down"
                }}
              </span>
            </span>
          </div>
        </div>
        <div v-if="menuSelecionado.Tamanho == 0" class="md-card aviso">
          Nenhum arquivo adicionado
        </div>
        <div
          v-if="!liberaTabela && menuSelecionado.Tamanho != 0"
          class="md-card aviso"
        >
          Nenhum arquivo encontrado
        </div>

        <ArquivosEntradaAtivos
          v-if="
            menuSelecionado.Status == 'Ativos' && menuSelecionado.Quantidade > 0
          "
          :guidProjeto="guidProjeto"
          :objetoProjeto="objetoProjeto"
          :metadadosProjeto="metadadosProjeto"
          :arquivosEntradaProjeto="arquivosEntradaProjetoFiltrada"
          :carregandoDownload="carregandoDownload"
          @recuperaProjeto="recuperaProjeto"
          @downloadArquivosSimples="downloadArquivosSimples"
          @downloadArquivosStreaming="downloadArquivosStreaming"
          @cancelarDownload="cancelarDownload"
        />
        <ArquivosExcluidos
          v-if="
            menuSelecionado.Status == 'Excluídos' &&
            menuSelecionado.Quantidade > 0
          "
          :guidProjeto="guidProjeto"
          :objetoProjeto="objetoProjeto"
          :metadadosProjeto="metadadosProjeto"
          :arquivosExcluidosProjeto="arquivosExcluidosProjetoFiltrada"
          @recuperaProjeto="recuperaProjeto"
        />
      </div>
    </transition-group>
    <Loading class="spinner" v-if="liberaLoading" />
  </div>

  <!-- <div v-else class="card arquivos aviso">Nenhum arquivo adicionado</div> -->
</template>

<script>
import { mapWritableState, mapActions } from "pinia";
import Loading from "@/components/Loading";
import JSZip from "jszip";
import Swal from "sweetalert2";
import ArquivosEntradaAtivos from "@/components/GestorBases/ArquivosEntradaAtivos.vue";
import ArquivosExcluidos from "@/components/GestorBases/ArquivosExcluidos.vue";

export default {
  name: "ArquivosDeEntrada",

  components: {
    ArquivosEntradaAtivos,
    ArquivosExcluidos,
    Loading,
  },

  props: {
    guidProjeto: String,
    objetoProjeto: Object,
    metadadosProjeto: Object,
    carregandoDownload: Object,
    arquivosEntradaProjeto: {
      type: Array,
      required: true,
    },
    arquivosExcluidosProjeto: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      header: [
        "Data de envio",
        "Data de expiração",
        "Nome do arquivo",
        "Tamanho do arquivo",
        "Tipo/Extensão",
        "Ações",
      ],

      menus: [
        {
          Status: "Ativos",
          Quantidade:
            this.metadadosProjeto.SumarioDiretorioEntrada.QtdArquivosAtivos,
          Tamanho:
            this.metadadosProjeto.SumarioDiretorioEntrada.TamanhoArquivosAtivos,
        },
        {
          Status: "Excluídos",
          Quantidade:
            this.metadadosProjeto.SumarioDiretorioEntrada.QtdArquivosExcluidos,
          Tamanho:
            this.metadadosProjeto.SumarioDiretorioEntrada
              .TamanhoArquivosExcluidos,
        },
      ],

      menuSelecionado: {
        Status: "Ativos",
        Quantidade:
          this.metadadosProjeto.SumarioDiretorioEntrada.QtdArquivosAtivos,
        Tamanho:
          this.metadadosProjeto.SumarioDiretorioEntrada.TamanhoArquivosAtivos,
      },

      abreCard: true,
      liberaLoading: false,

      ordemAtual: 0,
      ordemTipo: "desc",
      pesquisaNome: "",
    };
  },

  watch: {
    arquivosEntradaProjeto() {
      if (
        this.arquivosEntradaProjeto.length == 0 &&
        this.arquivosExcluidosProjeto.length == 0
      ) {
        this.abreCard = false;
      }
    },
    metadadosProjeto: {
      handler() {
        if (Object.keys(this.metadadosProjeto).length !== 0) {
          this.atualizaMenuSelecionado();
        }
      },
      deep: true,
    },
  },

  methods: {
    alteraMenu(menu, index) {
      this.menuSelecionado = {};
      this.menuSelecionado = { ...menu };
      this.atualizaMenuSelecionado();
    },

    async cancelarDownload(file, index) {
      this.$emit("cancelarDownload", file, index);
    },

    async downloadArquivosSimples(file, index) {
      this.$emit("downloadArquivosSimples", file, index);
    },

    async downloadArquivosStreaming(file, index) {
      this.$emit("downloadArquivosStreaming", file, index);
    },

    ordenar(indice, tipo) {
      if (this.ordemAtual != indice || this.ordemTipo != tipo) {
        this.ordemAtual = indice;
        this.ordemTipo = tipo;

        const chave = this.obterChave(indice);

        if (this.menuSelecionado.Status == "Ativos") {
          // this.arquivosExcluidosProjeto.sort((a, b) => {
          //   return new Date(b.DataCriacao) - new Date(a.DataCriacao);
          // });

          this.arquivosEntradaProjeto.sort((a, b) => {
            const valorA = a[chave];
            const valorB = b[chave];

            if (valorA === null || valorB === null) return 0;

            if (tipo === "asc") {
              return this.compararValores(valorA, valorB);
            } else {
              return this.compararValores(valorB, valorA);
            }
          });
        } else if (this.menuSelecionado.Status == "Excluídos") {
          // this.arquivosEntradaProjeto.sort((a, b) => {
          //   return new Date(b.DataCriacao) - new Date(a.DataCriacao);
          // });

          this.arquivosExcluidosProjeto.sort((a, b) => {
            const valorA = a[chave];
            const valorB = b[chave];

            if (valorA === null || valorB === null) return 0;

            if (tipo === "asc") {
              return this.compararValores(valorA, valorB);
            } else {
              return this.compararValores(valorB, valorA);
            }
          });
        }
      } else {
        this.ordemAtual = null;
        this.ordemTipo = null;
        this.arquivosEntradaProjeto.sort((a, b) => {
          return new Date(b.DataCriacao) - new Date(a.DataCriacao);
        });
      }
    },

    obterChave(indice) {
      switch (indice) {
        case 0:
          return "DataCriacao";
        case 1:
          return "ExpiraEm";
        case 2:
          return "NomeArquivo";
        case 3:
          return "Tamanho";
        case 4:
          return "Extensao";
        default:
          return "";
      }
    },
    compararValores(a, b) {
      if (typeof a === "string" && typeof b === "string") {
        return a.localeCompare(b);
      } else if (typeof a === "number" && typeof b === "number") {
        return a - b;
      } else {
        return new Date(b) - new Date(a);
      }
    },

    formatarTamanhoArquivo(tamanho) {
      if (tamanho < 1024) {
        return tamanho + " B";
      } else if (tamanho < 1024 * 1024) {
        return (tamanho / 1024).toFixed(2) + " KB";
      } else if (tamanho < 1024 * 1024 * 1024) {
        return (tamanho / (1024 * 1024)).toFixed(2) + " MB";
      } else {
        return (tamanho / (1024 * 1024 * 1024)).toFixed(2) + " GB";
      }
    },

    atualizaMenuSelecionado() {
      if (this.menuSelecionado.Status == "Ativos") {
        this.menuSelecionado.Quantidade =
          this.metadadosProjeto.SumarioDiretorioEntrada.QtdArquivosAtivos;
        this.menuSelecionado.Tamanho =
          this.metadadosProjeto.SumarioDiretorioEntrada.TamanhoArquivosAtivos;
      } else if (this.menuSelecionado.Status == "Excluídos") {
        this.menuSelecionado.Quantidade =
          this.metadadosProjeto.SumarioDiretorioEntrada.QtdArquivosExcluidos;
        this.menuSelecionado.Tamanho =
          this.metadadosProjeto.SumarioDiretorioEntrada.TamanhoArquivosExcluidos;
      }
    },

    recuperaProjeto() {
      this.$emit(
        "recuperaProjeto"
        // , () => {
        //   this.atualizaMenuSelecionado();
        // }
      );
    },
  },

  computed: {
    liberaTabela() {
      if (this.menuSelecionado.Quantidade.length > 0) {
        return true;
      }
      return false;
    },

    arquivosEntradaProjetoFiltrada() {
      if (!this.pesquisaNome || this.pesquisaNome == "") {
        return this.arquivosEntradaProjeto;
      }

      return this.arquivosEntradaProjeto.filter((file) =>
        file.NomeArquivo.toLowerCase().includes(this.pesquisaNome.toLowerCase())
      );
    },

    arquivosExcluidosProjetoFiltrada() {
      if (!this.pesquisaNome || this.pesquisaNome == "") {
        return this.arquivosExcluidosProjeto;
      }

      return this.arquivosExcluidosProjeto.filter((file) =>
        file.NomeArquivo.toLowerCase().includes(this.pesquisaNome.toLowerCase())
      );
    },

    resumoStatus() {
      const statusCounts = {
        ativos: 0,
        excluidos: 0,
      };

      statusCounts.ativos = this.arquivosEntradaProjeto.length;
      statusCounts.excluidos = this.arquivosExcluidosProjeto.length;

      const result = [];

      let isFirstItem = true;

      if (statusCounts.ativos > 0) {
        result.push(
          `${statusCounts.ativos} ${isFirstItem ? "arquivo(s)" : ""} ativo(s)`
        );
        isFirstItem = false;
      }
      if (statusCounts.excluidos > 0) {
        result.push(
          `${statusCounts.excluidos} ${
            isFirstItem ? "arquivo(s)" : ""
          } excluído(s)`
        );
        isFirstItem = false;
      }

      return result.join(", ");
    },
  },

  async mounted() {
    if (this.arquivosEntradaProjeto.length == 0) {
      this.abreCard = false;
    } else {
      this.arquivosEntradaProjeto.forEach((element) => {
        element.Progresso = 0;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  padding: 0;
  box-shadow: none;
  border-radius: 5px;
  border: none;
  gap: 5px;
}

.row-card {
  display: flex;
  flex-direction: row;
  width: 100%;

  &.arquivos {
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    color: #0070c0;
  }

  &.aviso {
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    padding: 0 15px;
  }
}

.titulo-box {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  border-bottom: 1px solid #0070c0;
  padding: 5px 10px;
  margin-bottom: 5px;
  cursor: default;
}

.menu-tabela {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-tabela {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20%;
}

.info-tamanho {
  font-size: 12px;
  color: black;
  font-weight: 300;
  line-height: 14px;
  display: flex;
  align-items: center;
  width: 17%;
}

.field-pesquisar {
  width: 230px;
  margin: 0;
}

.resumo-status {
  position: absolute;
  font-size: 12px;
  color: black;
  font-weight: 300;
  line-height: 14px;
  top: 25px;
  cursor: default;

  &.sumario {
    left: 300px;
  }
}

.projetos-menu {
  display: flex;
  gap: 10px;
}

.menu-tab {
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}

.item-tab {
  font-size: 14px;
  line-height: 18px;
  padding: 5px 10px;
  border: 1px solid #959595;
  border-radius: 5px;
  font-weight: 300;
  cursor: pointer;
  opacity: 0.8;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90px;

  &.selecionado {
    opacity: 1;
    border: 1px solid #272727;
    font-weight: 400;
  }

  &:hover {
    transform: scale(1.05);
    opacity: 1;
  }

  &:active {
    transform: scale(0.95);
    opacity: 1;
  }
}
.desc {
  line-height: 12px;
}

.icone-uploads {
  position: absolute;
  right: 25px;
  cursor: pointer;

  &.disab {
    cursor: default !important;
    pointer-events: none;
    color: #afafaf;
  }
}

.titulo {
  font-weight: 400;

  &.data {
    margin: 10px 0;
  }
}

.texto {
  font-weight: 300;
}

.desc {
  font-size: 12px;
  font-weight: 300;
}

.sub-linhas {
  display: flex;
  width: 100%;
  background-color: #ececec;
  border-bottom: 1px solid gray;
  min-height: 50px;

  &:hover {
    background-color: #e0e0e0;
  }
}

.sub-linha {
  width: calc(100%);
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  max-height: 400px;
  overflow-y: auto;
}

.tabela-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  // gap: 5px;
  padding: 0 0px;

  .cabecalho {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-weight: 600;
    flex: 1;
    cursor: default;

    &.nome-arquivo {
      width: 30%;
    }
  }
}

.box-ord {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 20px;
}

.icone-ordenacao {
  cursor: pointer;
  font-size: 30px;
  height: 26px;
  opacity: 0.3;
  display: flex;
  user-select: none;
  position: relative;
  left: 5px;

  &.active {
    opacity: 0.8;
    &:hover {
      opacity: 0.8;
    }
  }

  &:hover {
    opacity: 0.5;
  }
}

.aviso {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  align-self: center;
}

.spinner {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.cor-verde {
  color: #10b981;
}
.cor-amarelo {
  color: orange;
}
.cor-vermelho {
  color: #b71c1c;
}
.cor-azul {
  color: #1da0f2;
}

.disabled {
  cursor: default !important;
  pointer-events: none;
  opacity: 0.7;
}

.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.5s ease, opacity 0.5s ease;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
  opacity: 0;
}

.expand-enter-to {
  max-height: 400px;
  opacity: 1;
}
</style>
