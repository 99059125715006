<template>
  <VueHotelDatepicker
    :format="seletorDatasFormato"
    :placeholder="seletorDatasPlaceHolder"
    :maxNight="seletorDatasPeriodoMaximo"
    :weekList="seletorDatasWeekList"
    :monthList="seletorDatasMonthList"
    :fromText="seletorDatasFromText"
    :toText="seletorDatasToText"
    :resetText="seletorDatasResetText"
    :confirmText="seletorDatasConfirmText"
    :mobile="seletorDatasTipo"
    :minDate="seletorDatasMinDate()"
    :maxDate="seletorDatasMaxDate()"
    :separator="seletorDatasSeparador"
    :startDate="periodoDe"
    :endDate="periodoAte"
    v-on:confirm="onUpdate"
  />
</template>

<script>
import VueHotelDatepicker from '@northwalker/vue-hotel-datepicker'

export default {
  components: {
    VueHotelDatepicker
  },
  props: {
    periodoDe: {
      type: Date,
      default: null
    },
    periodoAte:{
      type: Date,
      default: null
    }
  },
  data() {
    return {
      seletorDatasFormato: 'DD/MM/YYYY',
      seletorDatasPlaceHolder : 'Selecione um período',
      seletorDatasPeriodoMaximo: 60,
      seletorDatasWeekList : ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
      seletorDatasMonthList : ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      seletorDatasFromText : 'De',
      seletorDatasToText: 'Até',
      seletorDatasSeparador: 'até',
      seletorDatasResetText: 'Limpar',
      seletorDatasConfirmText: 'Confirmar',
      seletorDatasTipo: 'mobile'
    };
  },
  mounted(){
  },
  methods: {
    onUpdate(data){
      this.$emit('atualizouData', {periodoDe: data.start, periodoAte: data.end});
    },
    seletorDatasMaxDate(){
      var data = this.$moment().add({'days': 1}).format("YYYY-MM-DD");
      return data;
    },
    seletorDatasMinDate(){
      var data = this.$moment().subtract({'months': 12}).format("YYYY-MM-DD");
      return data;
    }
  }
};


</script>
