<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: {
    dados: {
      type: Object,
      default: null
    }
  },
  methods: {
    determinaChartData () {

      var cores = ["#66bb6a", "#1da0f2","#ffa726", "purple", "lightblue","lightgreen", '#2f6a32', '#085c91', '#995c00', '#ff80ff'];
      var datasets = [];

      this.dados.requisicoes.forEach( function(item, index) {
        var dataset =  {
          backgroundColor: cores[index],
          borderColor: cores[index],
          data: item.QuantidadeFragmentada,
          label: item.Produto,
          fill: false
        };
        datasets.push(dataset);
      });

      return {
        labels: this.dados.periodo,
        datasets: datasets
      };

    }
  },
  mounted () {
    this.renderChart(this.determinaChartData(),
    {
      responsive: true,
      maintainAspectRatio: false
    });
  }
}
</script>
