<template>
  <div class="box-linha">
    <div class="linhas">
      <div class="item">
        {{ formatarData(projeto.DataCriacaoProjeto) }}
      </div>
      <div class="item nome">
        {{ projeto.NomeProjeto }}
        <span
          class="material-symbols-outlined icone-editar"
          @click="renomearProjeto(projeto)"
        >
          edit_square
        </span>
      </div>
      <div class="item">
        <span
          :id="'projeto-guid-' + index"
          class="material-symbols-outlined icone-info-projeto"
          v-clipboard:copy="projeto.IdentificadorProjeto"
        >
          assignment
        </span>
        <b-tooltip
          :target="'projeto-guid-' + index"
          triggers="hover"
          placement="bottom"
          variant="secondary"
          class="tooltip-projeto"
          boundary="window"
        >
          {{ projeto.IdentificadorProjeto }}
        </b-tooltip>
      </div>
      <!-- <div class="item">-Tipo-</div> -->
      <!-- <div class="item">
        {{ projeto.StatusId }}
        <span class="descricao">
          {{ formatarData(projeto.UltimoStatusEmDataBrt) }}
        </span>
      </div> -->
      <div class="item">
        {{ formatarData(projeto.VersaoCriadaEmDataBrt) }}
      </div>
      <div class="item acoes">
        <button
          class="botao-acessar"
          @click="irProjeto(projeto.IdentificadorProjeto)"
          v-if="projeto.StatusId == 'Ativo'"
        >
          Acessar
        </button>
        <span
          class="material-symbols-outlined icone-action cor-amarelo tooltip-container"
          @click="alertaDeletar(projeto)"
          :id="'botao-deletar-' + index"
          v-if="projeto.StatusId == 'Ativo'"
        >
          delete
          <span class="tooltip-text">Deletar projeto</span>
        </span>
        <!-- <span
          class="material-symbols-outlined icone-action cor-vermelho tooltip-container"
          :id="'botao-destruir-' + index"
          @click="alertaDestruir(projeto)"
          v-if="projeto.StatusId == 'Ativo'"
        >
          local_fire_department
          <span class="tooltip-text">Destruir projeto</span>
        </span> -->
        <span
          class="material-symbols-outlined icone-action cor-amarelo tooltip-container"
          :id="'botao-restaurar-' + index"
          @click="alertaRestaurar(projeto)"
          v-if="projeto.StatusId == 'Removido'"
        >
          restore_from_trash
          <span class="tooltip-text">Restaurar projeto</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { gestorProjetosService } from "@/services";
import { gestorArquivosService } from "@/services";
import { mapWritableState, mapActions } from "pinia";
import Swal from "sweetalert2";

export default {
  name: "MeusProjetos",

  props: {
    projetosUsuario: Array,
    projeto: Object,
    index: Number,
  },

  data() {
    return {
      abreDetalhes: false,
      file: null,

      reconstructFile: null,
    };
  },
  methods: {
    abrirDetelhes() {
      if (this.abreDetalhes == true) {
        this.abreDetalhes = false;
      } else {
        this.abreDetalhes = true;
      }
    },

    async renomearProjeto(projeto) {
      const { value } = await Swal.fire({
        title: "Renomear projeto",
        html: `
        <div class="input-container">
          <div class="swal-campo">
            <label id="nomeProjetoLabel" class="swal-nameProject">
              Novo nome do Projeto (3 a 120 caracteres)
            </label>
            <input type="text" id="nomeProjetoInput" class="swal2-input-projeto" maxlength="120" />
          </div>
        </div>`,
        showCancelButton: true,
        preConfirm: () => {
          const nomeProjeto = document.getElementById("nomeProjetoInput").value;
          const nomeProjetoLabel = document.getElementById("nomeProjetoLabel");

          if (nomeProjeto.length < 3 || nomeProjeto.length > 120) {
            nomeProjetoLabel.textContent =
              "Nome do Projeto - Número de caracteres inválido";
            nomeProjetoLabel.classList.add("cor-vermelho");
            return false;
          } else if (
            !/^[A-Za-z0-9._\s\-@çãõáéíóúâêôàèìòùÇÃÕÁÉÍÓÚÂÊÔÀÈÌÒÙ]*$/.test(
              nomeProjeto
            ) ||
            /\s{2,}/.test(nomeProjeto)
          ) {
            nomeProjetoLabel.textContent =
              "Nome do Projeto - Caracteres inválidos";
            nomeProjetoLabel.classList.add("cor-vermelho");
            return false;
          } else if (
            this.projetosUsuario.some(
              (projeto) => projeto.NomeProjeto === nomeProjeto
            )
          ) {
            nomeProjetoLabel.textContent =
              "Nome do Projeto - Esse nome já está em uso";
            nomeProjetoLabel.classList.add("cor-vermelho");
            return false;
          } else {
            nomeProjetoLabel.classList.remove("cor-vermelho");
          }
          return nomeProjeto;
        },
      });

      if (value) {
        this.enviaRenomearProjeto(projeto, value);
      }
    },

    async enviaRenomearProjeto(projeto, novoNome) {
      var objetoConsulta = {};
      objetoConsulta["IdentificadorProjeto"] = projeto.IdentificadorProjeto;
      objetoConsulta["NomeProjeto"] = novoNome;
      objetoConsulta["StatusId"] = projeto.StatusId;

      await gestorProjetosService
        .editaProjeto(objetoConsulta)
        .then((resultado) => {});

      this.$emit("recuperaHistoricoProjetos");
    },

    async deletarProjeto(projeto) {
      var objetoConsulta = {};
      objetoConsulta["IdentificadorProjeto"] = projeto.IdentificadorProjeto;

      try {
        const resultado = await gestorProjetosService.deletaProjeto(
          objetoConsulta
        );
        this.confirmAlert(true);
      } catch (error) {
        this.confirmAlert(false);
        throw error;
      }
    },

    async restaurarProjeto(projeto) {
      var objetoConsulta = {};
      objetoConsulta["IdentificadorProjeto"] = projeto.IdentificadorProjeto;

      try {
        const resultado = await gestorProjetosService.restauraProjeto(
          objetoConsulta
        );
        this.confirmAlert(true);
      } catch (error) {
        this.confirmAlert(false);
        throw error;
      }
    },

    async destruirProjeto(projeto) {
      var objetoConsulta = {};
      objetoConsulta["IdentificadorProjeto"] = projeto.IdentificadorProjeto;

      try {
        const resultado = await gestorProjetosService.destroiProjeto(
          objetoConsulta
        );
        this.confirmAlert(true);
      } catch (error) {
        this.confirmAlert(false);
        throw error;
      }
    },

    irProjeto(guid) {
      this.$router.push(`/gestor_bases/projetos/${guid}`);
    },

    formatarData(dataStr) {
      if (dataStr) {
        const data = new Date(dataStr);

        const dia = String(data.getDate()).padStart(2, "0");
        const mes = String(data.getMonth() + 1).padStart(2, "0");
        const ano = data.getFullYear();

        const horas = String(data.getHours()).padStart(2, "0");
        const minutos = String(data.getMinutes()).padStart(2, "0");
        const segundos = String(data.getSeconds()).padStart(2, "0");

        return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
      }
      return dataStr;
    },

    async alertaDeletar(item) {
      Swal.fire({
        icon: "warning",
        html:
          "<font size='5'>Deseja deletar o projeto </br>" +
          "<b>" +
          item.NomeProjeto +
          "</b>" +
          "?</font>",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<i class="fas fa-check-circle"></i> Sim!',
        confirmButtonAriaLabel: "Sim!",
        cancelButtonText: '<i class="fas fa-ban"></i> Não',
        cancelButtonAriaLabel: "Não",
        confirmButtonColor: "#2196f3",
        focusConfirm: false,
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deletarProjeto(item);
        }
      });
    },
    async alertaRestaurar(item) {
      Swal.fire({
        icon: "warning",
        html:
          "<font size='5'>Deseja restaurar o projeto </br>" +
          "<b>" +
          item.NomeProjeto +
          "</b>" +
          "?</font>",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<i class="fas fa-check-circle"></i> Sim!',
        confirmButtonAriaLabel: "Sim!",
        cancelButtonText: '<i class="fas fa-ban"></i> Não',
        cancelButtonAriaLabel: "Não",
        confirmButtonColor: "#2196f3",
        focusConfirm: false,
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.restaurarProjeto(item);
        }
      });
    },

    async alertaDestruir(item) {
      Swal.fire({
        icon: "warning",
        html:
          "<font size='5'>Ao destruír o projeto, </br>" +
          "<b>" +
          item.NomeProjeto +
          "</b>" +
          " todas as informações e arquivos armazenados serão perdidos. Deseja continuar?</font>",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<i class="fas fa-check-circle"></i> Sim!',
        confirmButtonAriaLabel: "Sim!",
        cancelButtonText: '<i class="fas fa-ban"></i> Não',
        cancelButtonAriaLabel: "Não",
        confirmButtonColor: "#2196f3",
        focusConfirm: false,
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.destruirProjeto(item);
        }
      });
    },

    confirmAlert(sucess) {
      if (sucess) {
        Swal.fire({
          icon: "success",
          title: "Ação realizada com sucesso!",
          showConfirmButton: false,
          timer: 2000,
          didOpen: () => {
            this.$emit("recuperaProjeto");
          },
          didClose: () => {
            this.$emit("recuperaHistoricoProjetos");
          },
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Ops, ocorreu um erro.",
          showConfirmButton: false,
          timer: 2000,
          didClose: () => {
            this.$emit("recuperaHistoricoProjetos");
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box-linha {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.linhas {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  background-color: #ececec;
  border-bottom: 1px solid gray;
  min-height: 60px;

  &:hover {
    background-color: #e0e0e0;
  }
}

.item {
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  cursor: default;

  &.nome {
    flex-direction: row;
  }

  &.acoes {
    flex-direction: row;
    gap: 10px;
  }
}

.icone-editar {
  position: relative;
  width: 0px;
  font-size: 18px;
  line-height: 20px;
  left: 10px;
  bottom: 5px;
  cursor: pointer;
  transition: 0.3s;
  color: #4d4d4d;

  &:hover {
    transform: scale(1.1);
    color: rgb(53, 53, 53);
  }

  &:active {
    transform: scale(0.9);
  }
}

.descricao {
  font-size: 10px;
  font-weight: 300;
}

.botao-acessar {
  background-color: transparent;
  border: none;
  padding: 5px 10px;
  color: white;
  font-weight: 600;
  font-size: 12px;
  transition: 0.3s;
  background-image: url(/img/tela-inicial/Botao-Nova.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px !important;

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
}

.icone-info-projeto {
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
}

.disabled {
  cursor: default !important;
  pointer-events: none;
  color: #a7a7a7;
}

.icone-action {
  transition: 0.3s;
  cursor: pointer;
  font-size: 26px;

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
}

.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: #6c757d;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 8px;
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.875rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #6c757d transparent;
}

.cor-verde {
  color: #10b981;
}
.cor-amarelo {
  color: orange;
}
.cor-vermelho {
  color: #b71c1c;
}
.cor-azul {
  color: #1da0f2;
}
</style>
