<template>
  <div class="text-center table-hover tableResult" v-if="usuario">
    <div class="table-responsive">
      <table id="tableComponent" class="table table-bordered table-striped">
        <thead>
          <tr>
            <th
              v-for="field in fields"
              :key="field"
              class="text-center"
              style="border: none"
            >
              {{ field }}
            </th>
          </tr>
        </thead>
        <LinhasLotes
          v-for="(item, index) in tableDataLote"
          :key="index"
          :fields="fields"
          :tableDataLote="tableDataLote"
          :index="index"
          :item="item"
          :usuario="usuario"
          :linhasAExecutar="linhasAExecutar"
        ></LinhasLotes>
      </table>
    </div>
  </div>
</template>

<script>
import LinhasLotes from "@/components/Historico/LinhasLotes.vue";
import { historicoService } from "@/services";
import { lotesStore } from "@/stores/lotes";
import { mapWritableState } from "pinia";

export default {
  name: "ResultadoHistoricoLotes",
  components: {
    LinhasLotes,
  },
  props: {
    tableDataLote: Array,
    counter: Number,
    usuario: Object,
  },

  data() {
    return {
      fields: [
        "Data",
        "Nome do Projeto",
        "Identificação",
        "Abordagem",
        "Progresso",
        "Status",
        "Ações",
        "",
      ],
      index: 0,
      lotesIniciados: null,
      msgErroUsuario: "",
      linhasAExecutar: 0,
    };
  },

  methods: {
    async recuperaLoteInfo() {
      const objetoHistorico = {
        periodo_de_brt: this.$moment()
          .subtract(20, "days")
          .format("YYYY-MM-DD"),
        periodo_ate_brt: this.$moment().format("YYYY-MM-DD"),
        status: "Iniciado",
      };
      const query = new URLSearchParams(objetoHistorico);

      try {
        this.lotesIniciados =
          await historicoService.recuperaDadosHistoricoLotes(query.toString());
        for (let j in this.lotesIniciados) {
          if (this.lotesIniciados[j].nome_usuario == this.usuario.Usuario) {
            this.linhasAExecutar +=
              this.lotesIniciados[j].qtde_linhas_total -
              this.lotesIniciados[j].qtde_linhas_processadas;
          }
        }
      } catch (error) {}
    },
  },
  async created() {
    this.recuperaLoteInfo();
  },

  computed: {
    ...mapWritableState(lotesStore, {
      historicoFiltrosLotes: "historicoFiltrosLotes",
      historicoResultadosLotes: "historicoResultadosLotes",
    }),
  },
};
</script>

<style scoped>
.tableResult {
  width: 100%;
  z-index: 0;
}
</style>
