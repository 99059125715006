<template>
  <div class="text-center tableResult">
    <div class="table-responsive">
      <table id="tableComponent" class="table table-bordered table-striped">
        <thead class="tableHead">
          <tr>
            <th v-for="field in fieldsSkynet" :key="field" class="text-center">
              {{ field }}
            </th>
          </tr>
          <div v-if="liberaSpinner">
            <Loading class="spinner" />
          </div>
        </thead>
        <LinhasSkynet
          v-for="(item, indexInt) in execucoesIntegracao"
          @showLoad="showLoad"
          @hideLoad="hideLoad"
          :key="indexInt"
          :index="index"
          :indexInt="indexInt"
          :fieldsSkynet="fieldsSkynet"
          :execucoesIntegracao="execucoesIntegracao"
          :item="item"
          :usuario="usuario"
        ></LinhasSkynet>
      </table>
    </div>
  </div>
</template>

<script>
import LinhasSkynet from "@/components/Historico/LinhasSkynet.vue";
import Loading from "@/components/Loading";

import { useSkynetStore } from "@/stores/skynet";
import { useSkynetMemoryStore } from "@/stores/skynetMemory";
import { mapState, mapActions } from "pinia";
import { skynetService } from "@/services";

export default {
  name: "ResultadoHistoricoSkynet",
  components: {
    LinhasSkynet,
    Loading,
  },
  props: {
    usuario: Object,
    execucoesIntegracao: Array,
    index: Number,
  },

  data() {
    return {
      fieldsSkynet: [
        "Data",
        "Fast Model",
        "Amostra",
        "Hash de Execução",
        "Progresso",
        "Status",
        "Ações",
      ],
      liberaSpinner: false,
      indexInt: 0,
    };
  },

  methods: {
    showLoad() {
      this.liberaSpinner = true;
    },
    hideLoad() {
      this.liberaSpinner = false;
    },
  },

  async created() {},

  computed: {
    ...mapState(useSkynetMemoryStore, [
      "identificadorMemory",
      "projectName",
      "resultadoIntegracaoMemory",
      "msgErroIntegracaoMemory",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.tableResult {
  width: 100%;
  z-index: 0;
  border-radius: 10px;
}
.tableHead {
  background-color: rgb(229 229 229 / 87%) !important;
  width: 100%;
  height: 55px;
}
.spinner {
  // width: 20px;
  position: absolute;
  left: 40%;
  top: 10%;
}
</style>