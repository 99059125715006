<template>
  <tbody class="tableLines">
    <tr class="rowResults">
      <td class="text-center rowResult">
        {{ formatarData(item.VersaoCriadaEmDataBrt) }}
      </td>
      <td class="text-center rowResult">
        {{ item.NomeProjeto }}
      </td>
      <td class="text-center rowResult">
        {{ item.StatusId }}
      </td>
      <td class="text-center rowResult">
        {{ item.TipoArquivoId }}
      </td>

      <div v-if="liberaSpinner">
        <Loading class="spinner" />
      </div>
    </tr>
  </tbody>
</template>

<script>
import { historicoService } from "@/services";
import Loading from "@/components/Loading";

import Swal from "sweetalert2";

export default {
  name: "LinhasHistoricoProjetos",

  components: { Loading },
  props: {
    resultadoHistorico: Array,
    index: Number,
    item: Object,
  },

  data() {
    return {
      liberaSpinner: false,

    };
  },

  methods: {
    formatarData(dataStr) {
      if (dataStr) {
        const data = new Date(dataStr);

        const dia = String(data.getUTCDate()).padStart(2, "0");
        const mes = String(data.getUTCMonth() + 1).padStart(2, "0");
        const ano = data.getUTCFullYear();

        const horas = String(data.getUTCHours()).padStart(2, "0");
        const minutos = String(data.getUTCMinutes()).padStart(2, "0");
        const segundos = String(data.getUTCSeconds()).padStart(2, "0");

        return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
      }
      return dataStr;
    },
  },

  mounted() {},

  beforeDestroy() {},

  created() {},

  computed: {},
};
</script>

<style lang="scss" scoped>
.rowResult {
  max-height: 70px;
  vertical-align: middle;
  cursor: default;
  border: none;
  width: 33%;
}
</style>
