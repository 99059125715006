<template>
  <div class="card arquivos">
    <div class="row-card arquivos">
      <span class="resumo-status">
        {{ resumoStatus }}
      </span>
      <span class="titulo titulo-box"> Arquivos aguardando envio </span>
      <div
        v-if="listaUpload.length > 0 && !liberaEnviarTodos"
        class="button-container todos"
        :class="{ disabled: !liberaEnviarTodos }"
        @click="cancelarUploadTodosArquivos()"
      >
        <div class="file is-boxed is primary" id="input-icone">
          <label class="file-label text-reader">
            <span class="file-cta">
              <span class="desc texto-upload todos cor-amarelo">
                Cancelar todos
              </span>
              <span class="material-symbols-outlined cor-amarelo">
                drive_folder_upload
              </span>
            </span>
          </label>
        </div>
      </div>
      <div
        v-if="listaUpload.length > 0 && liberaEnviarTodos"
        class="button-container todos"
        :class="{ disabled: !liberaEnviarTodos }"
        @click="uploadTodosArquivos()"
      >
        <div class="file is-boxed is primary" id="input-icone">
          <label class="file-label text-reader">
            <span class="file-cta">
              <span class="desc texto-upload todos cor-amarelo">
                Enviar todos
              </span>
              <span class="material-symbols-outlined cor-amarelo">
                drive_folder_upload
              </span>
            </span>
          </label>
        </div>
      </div>
      <div class="button-container">
        <div class="file is-boxed is primary" id="input-icone">
          <label class="file-label text-reader">
            <input
              type="file"
              ref="file"
              class="file-input"
              id="child-input"
              name="fileName"
              multiple
              @input="handleFileUpload('block', $event)"
            />
            <span class="file-cta">
              <span class="desc texto-upload cor-verde">
                Adicionar arquivos
              </span>
              <span class="material-symbols-outlined cor-verde">
                note_add
              </span>
            </span>
          </label>
        </div>
        <!-- <b-tooltip
          target="input-icone"
          triggers="hover"
          placement="bottom"
          variant="secondary"
          class="tooltipResults"
          boundary="window"
        >
          Adicionar arquivos
        </b-tooltip> -->
      </div>
      <span
        class="material-symbols-outlined icone-uploads"
        v-if="!abreCard"
        :class="{ disab: listaUpload.length == 0 }"
        @click="abreCard = !abreCard"
      >
        expand_more
      </span>
      <span
        class="material-symbols-outlined icone-uploads"
        v-if="abreCard"
        :class="{ disab: listaUpload.length == 0 }"
        @click="abreCard = !abreCard"
      >
        expand_less
      </span>
    </div>
    <transition name="expand">
      <div class="card-transition" v-if="abreCard">
        <div class="row-cards" v-if="abreCard">
          <div class="sub-linha tabela" v-if="listaUpload.length > 0">
            <div class="tabela-header">
              <div
                class="cabecalho"
                v-for="(cabecalho, i) in headerUpload"
                :key="i"
                :class="{ 'nome-arquivo': i == 0 }"
              >
                {{ cabecalho }}
              </div>
            </div>
            <div class="tabela-body">
              <div
                class="sub-linhas"
                v-for="file in listaUpload"
                :key="file.NomeArquivo"
              >
                <div class="sub-item nome-arquivo">
                  {{ file.NomeArquivo }}
                </div>
                <div class="sub-item" style="flex-direction: column">
                  {{ formatarTamanhoArquivo(file.tamanhoArquivo) }}
                </div>
                <div class="sub-item">
                  {{ file.tipoArquivo }}
                  <span v-if="file.tipoArquivo == ''">
                    {{ file.extensaoArquivo }}
                  </span>
                </div>
                <div class="sub-item barra-prog">
                  <span class="progr-fundo">
                    <span
                      class="progr-contagem"
                      :class="{ completo: file.progresso == 100 }"
                      :style="{ width: file.progresso + '%' }"
                    >
                    </span>
                    <div
                      class="progr-texto"
                      :class="{ branco: file.progresso > 55 }"
                    >
                      {{ file.progresso }}%
                    </div>
                  </span>
                </div>
                <div class="sub-item">
                  {{ file.status }}
                </div>
                <div
                  class="sub-item acoes"
                  :class="{ enviando: file.status == 'Enviando' }"
                >
                  <button
                    v-if="
                      file.status != 'Enviando' &&
                      file.status != 'Erro' &&
                      file.status != 'Cancelado' &&
                      file.status != 'Terminado'
                    "
                    class="botao-enviar"
                    :class="{
                      disabled: verificaEnvioArquivo(file),
                    }"
                    @click="uploadChunks(file)"
                  >
                    Enviar
                  </button>
                  <span
                    v-if="file.status == 'Enviando'"
                    class="material-symbols-outlined icone-deletar cor-vermelho"
                    @click="cancelarUploadChunks()"
                    :id="'botao-cancelar-upload' + file.id"
                  >
                    block
                  </span>
                  <b-tooltip
                    v-if="file.status == 'Enviando'"
                    :target="'botao-cancelar-upload' + file.id"
                    triggers="hover"
                    placement="bottom"
                    variant="secondary"
                    class="tooltip-acoes"
                    boundary="window"
                    container="body"
                  >
                    Cancelar Upload
                  </b-tooltip>
                  <div v-if="file.status != 'Enviando'">
                    <span
                      class="material-symbols-outlined icone-deletar"
                      @click="deletarPreAUpload(file)"
                      :id="'botao-deletar' + file.id"
                    >
                      delete_forever
                    </span>
                    <b-tooltip
                      :target="'botao-deletar' + file.id"
                      triggers="hover"
                      placement="bottom"
                      variant="secondary"
                      class="tooltip-acoes"
                      boundary="window"
                      container="body"
                    >
                      Apagar arquivo da lista de uploads
                    </b-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row-card aviso" v-if="abreCard && listaUpload.length != 0">
          <span
            class="aviso-box fundo-amarelo"
            v-if="listaUpload.some((arquivo) => arquivo.status === 'Enviando')"
          >
            *Os arquivos estão sendo enviados. Se o navegador for fechado antes
            do término o upload será cancelado.
          </span>
          <span class="aviso-box fundo-amarelo" v-if="nomeArquivoTratado">
            *Atenção! Caracteres inválidos em nome de arquivo(s) foram
            substituidos para atender as especificações do Portal Outbox.
          </span>
          <span class="aviso-box fundo-vermelho" v-if="erroUpload != ''">
            *{{ erroUpload }}
          </span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { gestorProjetosService } from "@/services";
import { userPreferenceStore } from "@/stores/userPreference";
import { mapWritableState, mapActions } from "pinia";

export default {
  name: "ArquivosParaUpload",

  components: {},

  props: {
    listaUpload: Array,
    cancelarUpload: Boolean,
    nomeArquivoTratado: Boolean,
    erroUpload: String,
  },

  data() {
    return {
      headerUpload: [
        "Nome",
        "Tamanho",
        "Tipo",
        "Progresso",
        "Status",
        "Ações",
      ],

      tooltips: {
        "botao-deletar": "Apagar arquivo da lista de uploads",
      },

      abreCard: false,
      liberaEnviarTodos: true,
    };
  },

  watch: {
    listaUpload: {
      handler() {
        if (
          this.listaUpload.some((file) => file.status === "Na fila") ||
          !this.listaUpload.some((file) => file.status === "Aguardando envio")
        ) {
          this.liberaEnviarTodos = false;
        } else {
          this.liberaEnviarTodos = true;
        }
      },
      deep: true,
    },
  },

  methods: {
    cancelarUploadChunks() {
      this.$emit("cancelarUploadChunks");
    },

    deletarPreAUpload(obj) {
      this.$emit("deletarPreAUpload", obj);

      if (this.listaUpload.length == 0) {
        this.abreCard = false;
      }
    },

    handleFileUpload(tipo, event) {
      this.$root.$emit("bv::hide::tooltip", "input-icone");
      this.$emit("handleFileUpload", tipo, event);
      this.abreCard = true;
    },

    uploadTodosArquivos() {
      this.$emit("uploadTodosArquivos");
    },

    cancelarUploadTodosArquivos() {
      this.$emit("cancelarUploadTodosArquivos");
    },

    uploadChunks(upload) {
      this.$emit("uploadChunks", upload);
    },

    verificaEnvioArquivo(file) {
      const extensoesPermitidas = [
        "txt",
        "tsv",
        "csv",
        "xml",
        "parquet",
        "avro",
        "orc",
        "xlsx",
        "xls",
        "Rdata",
        "Pickle",
      ];

      const isExtensaoInvalida = !extensoesPermitidas.includes(
        file.extensaoArquivo
      );

      let ultimoPontoIndex = file.NomeArquivo.lastIndexOf(".");

      if (ultimoPontoIndex === -1) ultimoPontoIndex = file.NomeArquivo.length;

      const nomeSemExtensao = file.NomeArquivo.substring(0, ultimoPontoIndex);

      if (
        file.status !== "Aguardando envio" ||
        file.jaExiste ||
        isExtensaoInvalida ||
        nomeSemExtensao.length <= 3 ||
        file.tamanhoArquivo == 0
      ) {
        if (isExtensaoInvalida) {
          file.status = "Extensão não permitida";
        }

        if (nomeSemExtensao.length <= 3 || file.tamanhoArquivo == 0) {
          file.status = "Arquivo inválido";
        }
        return true;
      }
      return false;
    },

    formatarData(dataStr) {
      if (dataStr) {
        const data = new Date(dataStr);

        const dia = String(data.getUTCDate()).padStart(2, "0");
        const mes = String(data.getUTCMonth() + 1).padStart(2, "0");
        const ano = data.getUTCFullYear();

        const horas = String(data.getUTCHours()).padStart(2, "0");
        const minutos = String(data.getUTCMinutes()).padStart(2, "0");
        const segundos = String(data.getUTCSeconds()).padStart(2, "0");

        return `${dia}/${mes}/${ano} - ${horas}:${minutos}:${segundos}`;
      }
      return dataStr;
    },

    formatarTamanhoArquivo(tamanho) {
      if (tamanho < 1024) {
        return tamanho + " B";
      } else if (tamanho < 1024 * 1024) {
        return (tamanho / 1024).toFixed(2) + " KB";
      } else if (tamanho < 1024 * 1024 * 1024) {
        return (tamanho / (1024 * 1024)).toFixed(2) + " MB";
      } else {
        return (tamanho / (1024 * 1024 * 1024)).toFixed(2) + " GB";
      }
    },
  },

  computed: {
    resumoStatus() {
      const statusCounts = {
        aguardandoEnvio: 0,
        enviando: 0,
        terminado: 0,
        cancelado: 0,
        erro: 0,
      };

      this.listaUpload.forEach((item) => {
        if (item.status === "Aguardando envio") statusCounts.aguardandoEnvio++;
        else if (item.status === "Enviando") statusCounts.enviando++;
        else if (item.status === "Terminado") statusCounts.terminado++;
        else if (item.status === "Cancelado") statusCounts.cancelado++;
        else if (item.status === "Erro") statusCounts.erro++;
      });

      const result = [];

      let isFirstItem = true;

      if (statusCounts.aguardandoEnvio > 0) {
        result.push(
          `${statusCounts.aguardandoEnvio} ${
            isFirstItem ? "arquivo(s)" : ""
          } aguardando envio`
        );
        isFirstItem = false;
      }
      if (statusCounts.enviando > 0) {
        result.push(
          `${statusCounts.enviando} ${isFirstItem ? "arquivo(s)" : ""} enviando`
        );
        isFirstItem = false;
      }
      if (statusCounts.terminado > 0) {
        result.push(
          `${statusCounts.terminado} ${
            isFirstItem ? "arquivo(s)" : ""
          } terminado(s)`
        );
        isFirstItem = false;
      }
      if (statusCounts.cancelado > 0) {
        result.push(
          `${statusCounts.cancelado} ${
            isFirstItem ? "arquivo(s)" : ""
          } cancelado(s)`
        );
        isFirstItem = false;
      }
      if (statusCounts.erro > 0) {
        result.push(
          `${statusCounts.erro} ${isFirstItem ? "arquivo(s)" : ""} com erro`
        );
        isFirstItem = false;
      }

      if (result.length === 0 && this.listaUpload.length > 0) {
        result.push(`${this.listaUpload.length} arquivo(s) adicionado(s)`);
      }
      if (result.length === 0 && this.listaUpload.length == 0) {
        result.push("Nenhum arquivo adicionado");
      }

      return result.join(", ");
    },
  },

  async mounted() {},
};
</script>

<style lang="scss" scoped>
.card-upload {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  width: 100%;
}

.button-container {
  transition: 0.5s;
  cursor: pointer;

  position: relative;
  right: 130px;
  bottom: 5px;
  width: 0px;

  &.todos {
    right: 300px;
  }

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.95);
  }
}

.file {
  padding: 0;
  transition: 0.5s;
}

.file-cta {
  height: 35px !important;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 34px;
  display: flex;
}

.box-todos {
  background: transparent;
  font-size: 34px;
  display: flex;
  transition: 0.5s;
  cursor: pointer;
  position: relative;
  right: 130px;
  bottom: 5px;
  width: 0px;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.95);
  }
}

.file-input {
  display: none;
}

.file-label {
  margin: 0 !important;
}

.file-icon {
  margin: 0 !important;
  cursor: pointer;
}

.texto-upload {
  width: 100px;
  position: relative;
  top: 4px;

  &.todos {
    width: 80px;
  }
}

.detalhes-projeto {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.card {
  display: flex;
  box-shadow: none;
  border-radius: 5px;
  border: none;
  gap: 5px;

  &.dados {
    background-color: #3b3b3be0;
    color: white;
  }

  &.aviso {
    justify-content: center;
    align-items: center;
    font-weight: 300;
    font-size: 16px;
    margin-top: 10px;
    padding: 15px 0;
  }
}

.row-card {
  display: flex;
  flex-direction: row;
  width: 100%;

  &.arquivos {
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    color: #0070c0;
  }

  &.aviso {
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    padding: 15px;
  }
}

.aviso-box {
  font-size: 14px;
  font-weight: 300;
  line-height: 12px;
  color: white;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 30px);
  height: 35px;
  border-radius: 5px;
}

.col-cards {
  display: flex;
  width: 100%;
  gap: 10px;
  padding: 0 10px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  &.info-guid {
    flex-direction: row;
    width: 230px;
  }
}
.col-card {
  display: flex;
  flex-direction: column;
  min-width: 275px;
  gap: 5px;

  &.info-guid {
    flex-direction: row;
    width: 230px;
  }
}

.card-transition {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.row-cards {
  width: 100%;
}

.item {
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 5px;

  &.titulo {
    font-size: 18px;
    line-height: 21px;
    border-bottom: 1px solid white;
    width: 100%;
    justify-content: space-between;
    padding: 5px 10px;
    cursor: default;
  }

  .icone-mensagem {
    color: #ffee00;
    font-size: 18px;
    margin-left: 10px;
    cursor: default;
  }

  &.arquivos {
    font-size: 18px;
    line-height: 21px;
    border-bottom: 1px solid white;
    width: 100%;
    justify-content: center;
    padding: 5px 10px;
  }
}

.bloco {
  display: flex;
  align-items: center;
  gap: 5px;
}

.titulo-box {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  border-bottom: 1px solid #0070c0;
  padding: 5px 10px;
  margin-bottom: 5px;
}

.resumo-status {
  position: absolute;
  left: 300px;
  top: 25px;
  font-size: 12px;
  color: black;
  font-weight: 300;
  line-height: 14px;
  cursor: default;
}

.icone-uploads {
  cursor: pointer;
  position: absolute;
  right: 25px;
}

.titulo {
  font-weight: 400;

  &.data {
    margin: 10px 0;
  }

  &.secao {
    font-size: 18px;
    line-height: 21px;
    font-weight: 300;
  }
}

.texto {
  font-weight: 300;
}

.desc {
  font-size: 12px;
  font-weight: 300;
}

.icone-info-projeto {
  cursor: pointer;
  transition: 0.3s;
  font-weight: 300;

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
}

.sub-linhas {
  display: flex;
  width: 100%;
  background-color: #ececec;
  border-bottom: 1px solid gray;
  min-height: 50px;

  &:hover {
    // background-color: #4356a557;
    background-color: #e0e0e0;
  }
}

.sub-linha {
  width: calc(100%);
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
}

.tabela-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 0 5px;

  .cabecalho {
    display: flex;
    width: 20%;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-weight: 600;
    cursor: default;

    &.nome-arquivo {
      width: 30%;
    }
  }
}

.tabela-body {
  display: flex;
  width: 100%;
  flex-direction: column;
  // padding: 0 5px;
}

.sub-item {
  display: flex;
  width: 20%;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 14px;
  font-weight: 400;
  cursor: default;
  text-align: center;
  word-break: break-all;
  padding: 0 5px;

  &.nome-arquivo {
    width: 30%;
  }

  &.barra-prog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.acoes {
    gap: 13px;
    justify-content: flex-end;

    &.enviando {
      justify-content: center;
    }
  }
}

.progr-fundo {
  border: 1px solid #eeeeee;
  background-color: white;
  height: 25px;
  width: 90%;
  border-radius: 20px;
  overflow: hidden;
}

.progr-contagem {
  background-color: #1da0f2;
  border-radius: 20px 0px 0px 20px;
  height: 100%;
  display: flex;

  &.completo {
    border-radius: 20px;
  }
}

.progr-texto {
  position: relative;
  bottom: 21px;
  z-index: 10;
  height: 1px;
  line-height: 16px;
  // color: #b7b7b7;
  color: rgb(75, 75, 75);

  &.branco {
    color: white;
  }
}

.icon-action {
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
}

.botao-enviar {
  background-color: transparent;
  border: none;
  padding: 5px 10px;
  color: white;
  font-weight: 600;
  font-size: 12px;
  transition: 0.3s;
  background-image: url(/img/tela-inicial/Botao-Nova.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 30px !important;

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
}

.icone-deletar {
  transition: 0.3s;
  cursor: pointer;
  color: #b71c1c;
  font-size: 24px;
  display: inline-block;
  position: relative;

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
}

.fundo-verde {
  background-color: #10b981;
  color: white;
}
.fundo-amarelo {
  background-color: #f6f88d;
  color: black;
}
.fundo-vermelho {
  background-color: #b71c1c;
  color: white;
}

.disabled {
  cursor: default !important;
  pointer-events: none;
  color: white;
  opacity: 0.5;
}

.disab {
  cursor: default !important;
  pointer-events: none;
  color: #afafaf;
}

.cor-verde {
  color: #10b981;
}
.cor-amarelo {
  color: orange;
}
.cor-vermelho {
  color: #b71c1c;
}
.cor-azul {
  color: #1da0f2;
}

.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.5s ease, opacity 0.5s ease;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
  opacity: 0;
}

.expand-enter-to {
  max-height: 400px;
  opacity: 1;
}
</style>
