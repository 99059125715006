<script>
import { Pie } from 'vue-chartjs'

export default {
  extends: Pie,
  props: {
    dados: {
      type: Object,
      default: null
    }
  },
  methods: {
    determinaChartData () {

      var cores = ["#66bb6a", "#1da0f2","#ffa726", "purple", "lightblue","lightgreen", '#2f6a32', '#085c91', '#995c00', '#ff80ff'];
      var labels = [];
      var datasets = [
        {
          backgroundColor: [],
          data: []
        }
      ];

      this.dados.requisicoes.forEach( function(item, index) {
        var quantidadeTotal = item.QuantidadeFragmentada.reduce(function(total, num){return Number(total)+Number(num);});
        datasets[0].data.push(quantidadeTotal);
        datasets[0].backgroundColor.push(cores[index]);
        labels.push(item.Produto);
      });

      return {
        labels: labels,
        datasets: datasets
      };

    }
  },
  mounted () {
    this.renderChart(this.determinaChartData(), {
      title: {
        display: true,
        text: 'Distribuição das requisições no período'
      },
      legend: {
        position: 'top',
        fullWidth: true
      },
      responsive: true,
      maintainAspectRatio: false
    });
  }
}
</script>
