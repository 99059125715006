<template>
  <!-- <span
          class="botao-limpar cor-amarelo"
          @click="alertaExcluirTudoLixeira()"
          :class="{ disabled: arquivosExcluidosProjeto.length == 0 }"
        >
          <span class="material-symbols-outlined icone-limpar">
            delete_forever
          </span>
          <span class="texto-limpar"> Limpar </span>
        </span> -->
  <div style="width: 100%">
    <div class="tabela-body">
      <div
        class="sub-linhas"
        v-for="file in arquivosExcluidosProjeto"
        :key="file.NomeArquivo"
      >
        <div class="sub-item data-arquivo">
          {{ formatarData(file.DataCriacao) }}
        </div>
        <div class="sub-item data-arquivo">
          <span v-if="file.ExpiraEm">
            {{ formatarData(file.ExpiraEm) }}
          </span>
          <span class="desc" v-else> Sem expiração </span>
        </div>
        <div class="sub-item nome-arquivo">
          {{ file.NomeArquivo }}
        </div>
        <div class="sub-item">
          {{ formatarTamanhoArquivo(file.Tamanho) }}
        </div>
        <div class="sub-item">
          {{ file.Tipo }}
          <span v-if="file.Tipo">/</span>
          {{ file.Extensao }}
        </div>
        <div class="sub-item acoes">
          <span
            class="material-symbols-outlined icone-action cor-amarelo"
            @click="alertaRestaurar(file)"
            id="botao-restaurar"
          >
            restore_from_trash
          </span>
          <!-- <span
                class="material-symbols-outlined icone-action cor-vermelho"
                id="botao-destruir"
              >
                delete_forever
              </span> -->
          <b-tooltip
            v-for="(tooltip, id) in tooltips"
            :key="id"
            :target="id"
            triggers="hover"
            placement="bottom"
            variant="secondary"
            class="tooltip-acoes"
            boundary="viewport"
          >
            {{ tooltip }}
          </b-tooltip>
        </div>
      </div>
    </div>
    <span
      class="aviso-box fundo-amarelo"
      v-if="this.arquivosExcluidosProjeto.length > 0"
    >
      *Os arquivos que ficarem na "Lixeira" mais de 7 dias serão excluídos
      automáticamente.
    </span>
  </div>

  <!-- <div v-else class="card arquivos aviso">Nenhum arquivo adicionado</div> -->
</template>

<script>
import { gestorProjetosService } from "@/services";
import { gestorArquivosService } from "@/services";
import { userPreferenceStore } from "@/stores/userPreference";
import { mapWritableState, mapActions } from "pinia";
import Loading from "@/components/Loading";
import JSZip from "jszip";
import Swal from "sweetalert2";

export default {
  name: "ArquivosExcluidos",

  components: {},

  props: {
    guidProjeto: String,
    objetoProjeto: Object,
    metadadosProjeto: Object,
    arquivosExcluidosProjeto: Array,
  },

  data() {
    return {
      header: [
        "Data",
        "Nome do arquivo",
        "Tamanho do arquivo",
        "Tipo/Extensão",
        "Ações",
      ],

      tooltips: {
        "botao-restaurar": "Restaurar arquivo",
        // "botao-destruir": "Destruir arquivo permanentemente",
      },

      abreCard: true,
    };
  },

  watch: {
    arquivosExcluidosProjeto() {
      if (this.arquivosExcluidosProjeto.length == 0) {
        this.abreCard = false;
      } else {
        this.abreCard = true;
      }
    },
  },

  methods: {
    async restaurarArquivo(file) {
      var objetoConsulta = {};
      objetoConsulta["IdentificadorProjeto"] = this.guidProjeto;
      objetoConsulta["NomeArquivo"] = file.NomeArquivo;

      try {
        const resultado = await gestorArquivosService.restauraArquivo(
          objetoConsulta
        );
        this.confirmAlert(true);
      } catch (error) {
        this.confirmAlert(false);

        throw error;
      }
    },

    async destruirArquivo(file, tipo) {
      var objetoConsulta = {};
      objetoConsulta["IdentificadorProjeto"] = this.guidProjeto;
      objetoConsulta["IdentificadorArquivo"] = file.IdentificadorArquivo;

      try {
        const resultado = await gestorArquivosService.destroiArquivo(
          objetoConsulta
        );
        if (tipo == "unico") {
          this.confirmAlert(true);
        }
      } catch (error) {
        this.confirmAlert(false);
        throw error;
      }
    },

    async destruirTodosArquivosLixeira() {
      for (const file of this.arquivosExcluidosProjeto) {
        await this.destruirArquivo(file, "todos");
      }

      this.confirmAlert(true);
    },

    formatarData(dataStr) {
      if (dataStr) {
        const data = new Date(dataStr);

        const dia = String(data.getUTCDate()).padStart(2, "0");
        const mes = String(data.getUTCMonth() + 1).padStart(2, "0");
        const ano = data.getUTCFullYear();

        const horas = String(data.getUTCHours()).padStart(2, "0");
        const minutos = String(data.getUTCMinutes()).padStart(2, "0");
        const segundos = String(data.getUTCSeconds()).padStart(2, "0");

        return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
      }
      return dataStr;
    },

    formatarTamanhoArquivo(tamanho) {
      if (tamanho < 1024) {
        return tamanho + " B";
      } else if (tamanho < 1024 * 1024) {
        return (tamanho / 1024).toFixed(2) + " KB";
      } else if (tamanho < 1024 * 1024 * 1024) {
        return (tamanho / (1024 * 1024)).toFixed(2) + " MB";
      } else {
        return (tamanho / (1024 * 1024 * 1024)).toFixed(2) + " GB";
      }
    },

    dataHoraAtual() {
      const agora = new Date();
      const dia = String(agora.getDate()).padStart(2, "0");
      const mes = String(agora.getMonth() + 1).padStart(2, "0");
      const ano = agora.getFullYear();
      const horas = String(agora.getHours()).padStart(2, "0");
      const minutos = String(agora.getMinutes()).padStart(2, "0");
      const segundos = String(agora.getSeconds()).padStart(2, "0");

      return `${dia}/${mes}/${ano} ${horas}:${minutos}:${segundos}`;
    },

    async alertaRestaurar(file) {
      Swal.fire({
        icon: "warning",
        html:
          "<font size='5'>Deseja restaurar o arquivo </br>" +
          "<b>" +
          file.NomeArquivo +
          "</b>" +
          " da lixeira?</font>",
        // width: "35%",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<i class="fas fa-check-circle"></i> Sim!',
        confirmButtonAriaLabel: "Sim!",
        cancelButtonText: '<i class="fas fa-ban"></i> Não',
        cancelButtonAriaLabel: "Não",
        confirmButtonColor: "#2196f3",
        focusConfirm: false,
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.restaurarArquivo(file);
        }
      });
    },

    async alertaDestruir(file) {
      Swal.fire({
        icon: "warning",
        html:
          "<font size='5'>Deseja apagar permanentemente o arquivo </br>" +
          "<b>" +
          file.NomeArquivo +
          "</b>" +
          " da lixeira?</font>",
        // width: "35%",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<i class="fas fa-check-circle"></i> Sim!',
        confirmButtonAriaLabel: "Sim!",
        cancelButtonText: '<i class="fas fa-ban"></i> Não',
        cancelButtonAriaLabel: "Não",
        confirmButtonColor: "#2196f3",
        focusConfirm: false,
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.destruirArquivo(file, "unico");
        }
      });
    },

    async alertaExcluirTudoLixeira(file) {
      Swal.fire({
        icon: "warning",
        html: "<font size='5'>Deseja apagar permanentemente todos os arquivos da lixeira?</font>",
        // width: "35%",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: '<i class="fas fa-check-circle"></i> Sim!',
        confirmButtonAriaLabel: "Sim!",
        cancelButtonText: '<i class="fas fa-ban"></i> Não',
        cancelButtonAriaLabel: "Não",
        confirmButtonColor: "#2196f3",
        focusConfirm: false,
        allowEnterKey: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.destruirTodosArquivosLixeira();
        }
      });
    },

    confirmAlert(sucess) {
      if (sucess) {
        Swal.fire({
          icon: "success",
          title: "Ação realizada com sucesso!",
          showConfirmButton: false,
          timer: 2000,
          didOpen: () => {
            this.$emit("recuperaProjeto");
          },
          didClose: () => {
            // this.$emit("recuperaProjeto");
          },
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Ops, ocorreu um erro.",
          showConfirmButton: false,
          timer: 2000,
          didClose: () => {},
        });
      }
    },
  },

  computed: {},

  async mounted() {
    if (this.arquivosExcluidosProjeto.length == 0) {
      this.abreCard = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  box-shadow: none;
  border-radius: 5px;
  border: none;
  gap: 5px;
}

.row-card {
  display: flex;
  flex-direction: row;
  width: 100%;

  &.arquivos {
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 20px;
    color: #0070c0;
  }

  &.aviso {
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    padding: 0 15px;
  }
}

.titulo-box {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid #0070c0;
  padding: 5px 10px;
  margin-bottom: 5px;
}

.resumo-status {
  position: absolute;
  font-size: 12px;
  color: black;
  font-weight: 300;
  line-height: 14px;
  top: 25px;

  &.sumario {
    left: 35px;
  }
  &.espaco {
    right: 80px;
    display: flex;
  }
}

.botao-limpar {
  cursor: pointer;
  margin: 0 10px;
  font-weight: 400;
  transition: 0.3s;
  font-size: 12px;
  position: relative;
  bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
}

.icone-uploads {
  cursor: pointer;
  position: absolute;
  right: 25px;

  &.disab {
    cursor: default !important;
    pointer-events: none;
    color: #afafaf;
  }
}

.titulo {
  font-weight: 400;

  &.data {
    margin: 10px 0;
  }
}

.texto {
  font-weight: 300;
}

.desc {
  font-size: 12px;
  font-weight: 300;
}

.sub-linhas {
  display: flex;
  width: 100%;
  background-color: #ececec;
  border-bottom: 1px solid gray;
  min-height: 50px;

  &:hover {
    background-color: #e0e0e0;
  }
}

.sub-linha {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.tabela {
  width: calc(100%);
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  max-height: 500px;
  overflow-y: auto;
}

.tabela-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 0 5px;

  .cabecalho {
    display: flex;
    width: 25%;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-weight: 600;

    &.nome-arquivo {
      width: 30%;
    }
  }
}

.tabela-body {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.sub-item {
  display: flex;
  width: 25%;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 14px;
  font-weight: 400;
  cursor: default;
  text-align: center;
  word-break: break-all;
  padding: 0px;
  flex: 1;

  &.nome-arquivo {
    width: 30%;
  }
  &.data-arquivo {
    flex-direction: column;
    gap: 5px;
  }

  &.acoes {
    gap: 10px;
  }
}

.icone-action {
  transition: 0.3s;
  cursor: pointer;
  font-size: 26px;

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
}

.aviso-box {
  font-size: 14px;
  font-weight: 300;
  line-height: 12px;
  color: white;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 60px);
  height: 35px;
  border-radius: 5px;
  margin: 15px;
}

.cor-verde {
  color: #10b981;
}
.cor-amarelo {
  color: orange;
}
.cor-vermelho {
  color: #b71c1c;
}

.fundo-verde {
  background-color: #10b981;
  color: white;
}
.fundo-amarelo {
  background-color: #f6f88d;
  color: black;
}
.fundo-vermelho {
  background-color: #b71c1c;
  color: white;
}

.disabled {
  cursor: default !important;
  pointer-events: none;
  color: white;
  opacity: 0.7;
}

.expand-enter-active,
.expand-leave-active {
  transition: max-height 0.5s ease, opacity 0.5s ease;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
  opacity: 0;
}

.expand-enter-to {
  max-height: 500px;
  opacity: 1;
}
</style>
