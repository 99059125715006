<template>
  <div>
    <i :class="[icone, 'fa-' + tamanho]"></i>
    <md-tooltip v-if="tooltipDirecao != 'nenhum'" :md-direction="tooltipDirecaoInterno">{{descricao}}</md-tooltip>
  </div>

</template>

<script>

export default {
  name: "icone-produto",
  props: {
    descricao: {
      type: String,
      default: null
    },
    tamanho: {
      type: String,
      default: null
    },
    tooltipDirecao:{
      type: String,
      default: "nenhum"
    }
  },
  data() {
    return {

    };
  },
  computed: {
    tooltipDirecaoInterno : function(){
      switch (this.tooltipDirecao){
        case "cima":
          return "top";
        case "baixo":
          return "bottom";
        case "esquerda":
          return "left";
        case "direita":
          return "right";
      }
      return "";
    },

    icone : function(){
      var icone =  ['fas', 'fa-times'];

      switch (this.descricao){
        case "Situação cadastral da Pessoa Física":
          icone = ['fas', 'fa-fingerprint'];
          break;
        case 'CEP Desfavorecido Socialmente':
          icone = ['far', 'fa-sad-tear'];
          break;
        case "CEP Desfavorecido Socialmente - Grau de Severidade":
            icone = ['fas', 'fa-house-damage'];
            break;
        case "Cálculo de semelhança entre nomes":
            icone = ['fas', 'fa-assistive-listening-systems'];
            break;
        case "CEP Alto Padrão":
            icone = ['far', 'fa-gem'];
            break;
        case "CEP Favorecido Socialmente":
            icone = ['far', 'fa-smile'];
            break;
        case "Distância - Ponto referência":
            icone = ['far', 'fa-compass'];
            break;
        case "Distância do CEP à Fronteira mais próxima":
            icone = ['fas', 'fa-globe-americas'];
            break;
        case "Distância do CEP até Agência - Por Código do Banco":
            icone = ['far', 'fa-credit-card'];
            break;
        case "Distância do CEP até Agência - Por Nome do Banco":
            icone = ['fas', 'fa-credit-card'];
            break;
        case "Distância entre dois CEPs":
            icone = ['fas', 'fa-route'];
            break;
        case "Indicador de óbito da Pessoa Física":
            icone = ['fas', 'fa-dizzy'];
            break;
        case "Indicadores do IBGE do CEP":
            icone = ['fas', 'fa-search-location'];
            break;
        case "Inferência da renda da profissão informada":
            icone = ['fas', 'fa-suitcase'];
            break;
        case "Inferência do gênero da Pessoa Física":
            icone = ['fas', 'fa-transgender'];
            break;
        case "Informações de agências bancárias em funcionamento":
            icone = ['fas', 'fa-money-check-alt'];
            break;
        case "Modelos de processamento de Score da Pessoa Física":
            icone = ['fas', 'fa-weight'];
            break;
        case "Modelos de Score da Pessoa Física":
            icone = ['fas', 'fa-tachometer-alt'];
            break;
        case "Modelos de Score da Pessoa Jurídica":
            icone = ['fas', 'fa-tachometer-alt'];
            break;
        case "Referências bancárias da Pessoa Física":
            icone = ['fas', 'fa-hand-holding-usd'];
            break;
        case "Renda Presumida do CEP":
            icone = ['fas', 'fa-search-dollar'];
            break;
        case "Score - CAEDU":
            icone = ['fas', 'fa-tshirt'];
            break;
        case "Score do CEP":
            icone = ['fas', 'fa-award'];
            break;
        case "Book 201 de variáveis da Pessoa Jurídica":
            icone = ['fas', 'fa-building'];
            break;
        case "Book 201-01 de variáveis da Pessoa Jurídica":
            icone = ['fas', 'fa-building'];
            break;
        case "Book 201-02 de variáveis da Pessoa Jurídica":
            icone = ['fas', 'fa-user-tie'];
            break;
        case "Book 301 de informações postais do CEP":
            icone = ['fas', 'fa-shipping-fast'];
            break;
        case "Consulta de múltiplos produtos em uma única requisição":
            icone = ['', ''];
            break;
      }

      return icone;
    }
  },

  watch: {

    descricao(valor){


    }
  }


};


</script>
